export function formatDate(input) {
  if (input === null) return "-";
  var datePart = input.match(/\d+/g),
    year = datePart[0].substring(0),
    month = datePart[1],
    day = datePart[2];

  return day + "-" + month + "-" + year;
}

export function nullCheck(input) {
  let data = input;
  if (input === null || input === undefined) {
    data = "-";
  }
  return data;
}

export function nullCheckArray(input) {
  let data = input;
  if (input === null || input === undefined) {
    data = [];
  }
  return data;
}

export function transferTypeCheck(input) {
  let type;
  switch (input) {
    case 100:
      type = "UPI";
      break;
    case 200:
      type = "Crypto";
      break;
    case 300:
      type = "Mobikwik";
      break;
    case 400:
      type = "Bank Transfer";
      break;
    case 500:
      type = "Cash";
      break;
    default:
      type = input;
      break;
  }
  return type;
}

export function typeCheck(input) {
  let type;
  switch (input) {
    case "Deposit:crypto":
      type = "Crypto";
      break;
    case "Deposit:fiat":
      type = "Fiat";
      break;
    case "Withdraw:fiat":
      type = "Crypto";
      break;
    case "Withdraw:crypto":
      type = "Fiat";
      break;

    default:
      type = input;
      break;
  }
  return type;
}
