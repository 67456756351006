import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../AuthContext/AuthContext";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "./currency.scss";
import Modal from "../../Components/Modal";
import NewCurrency from "../../Components/NewCurrency/NewCurrency";
import Loading from "../../Components/Loading/Loading";

function Currency() {
  const [isLoading, setIsLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [currencyData, setCurrencyData] = useState({});
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 25,
  });
  const [rowCountState, setRowCountState] = useState();

  const History = useHistory();
  const { user } = useContext(AuthContext);
  const location = useLocation();

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(async () => {
    await sleep(3000);
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, []);

  useEffect(() => {
    if (
      jwt_decode(user.access).urole != "ADMIN" &&
      jwt_decode(user.access).urole != "MANAGEMENT"
    ) {
      History.push("/");
    }
  }, [user.access, History]);

  useEffect(() => {
    const getAllCurrencyData = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `baseAPI/cmrapi/ADMIN/getAllCurrency?_sort=created_at&_order=ASC&page=${page}&_limit=25`,

          {
            headers: {
              Authorization: `Bearer ${user.access}`,
            },
          }
        );
        setCurrencyData(res.data.data);
        setRowCountState(res.data.count);
        setIsLoading(false);
      } catch {}
    };
    getAllCurrencyData();
  }, [page, user]);

  console.log("Currency Data:", currencyData);

  const columns = [
    {
      field: "icon_url",
      headerName: "Icon",
      width: "90",
      renderCell: (params) => {
        return (
          <>
            <img
              className="currencyIcon"
              src={params.row.icon_url}
              alt={params.row.id}
            />
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: "180",
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => {
                History.push(`/currencydata/${params.row.id}`);
              }}
            >
              View Details
            </button>
          </>
        );
      },
    },
    { field: "id", headerName: "ID", width: "90" },
    { field: "name", headerName: "Name", width: "120" },
    { field: "type", headerName: "Type", width: "70" },
    { field: "homepage", headerName: "Home Page", width: "240" },
    { field: "deposit_fee", headerName: "Deposit Fee", width: "180" },
    { field: "withdraw_fee", headerName: "Withdraw Fee", width: "180" },
    { field: "price", headerName: "Price", width: "180" },
    {
      field: "min_deposit_amount",
      headerName: "Min Deposit Amount",
      width: "180",
    },
    {
      field: "min_collection_amount",
      headerName: "Min Collection Amount",
      width: "180",
    },
    {
      field: "min_withdraw_amount",
      headerName: "Min Withdraw Amount",
      width: "180",
    },
    {
      field: "withdraw_limit_24h",
      headerName: "Withdraw Limit 24H",
      width: "180",
    },
    {
      field: "withdraw_limit_72h",
      headerName: "Withdraw Limit 72H",
      width: "180",
    },
    { field: "position", headerName: "Position", width: "90" },

    //   {field:"", headerName:"",width:""},
  ];

  const rows = currencyData;

  return (
    <>
      <div className="kyc__body">
        <div className="Relative">
          <div className="AlignRight">
            <button
              style={{ marginLeft: "125px" }}
              onClick={() => setIsOpen(true)}
            >
              Add New Currency
            </button>
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              sx={{
                color: "white",
                backgroundColor: "#191c24",
                height: "80vh",
              }}
              rows={rows}
              columns={columns}
              pagination
              {...rowsState}
              paginationMode="server"
              pageSize={25}
              rowsPerPageOptions={[25]}
              page={page - 1}
              rowCount={rowCountState}
              onPageChange={(newPage) => {
                setPage(newPage + 1);
              }}
            />
          </div>
        )}
      </div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <NewCurrency type="Create New Currency" />
      </Modal>
    </>
  );
}

export default Currency;
