import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext/AuthContext";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";

import "../styles/users.scss";
import Loading from "../Components/Loading/Loading";
import { SearchOutlined } from "@mui/icons-material";
import swal from "sweetalert";

function Users() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchInfo, setSearchInfo] = useState({
    column: "first_name",
    word: "",
  });

  const [urole, setUrole] = useState("MEMBER");

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [rowCountState, setRowCountState] = useState();

  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 25,
  });

  const { user } = useContext(AuthContext);
  const History = useHistory();

  const location = useLocation();

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(async () => {
    await sleep(3000);
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, []);

  useEffect(() => {
    if (jwt_decode(user.access).urole != "ADMIN") {
      History.push("/");
    }
  }, [user.access, History]);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "username", headerName: "Username", width: 240 },
    { field: "uid", headerName: "UID", width: 130 },
    { field: "level", headerName: "KYC Level", width: 70 },
    {
      field: "provider",
      headerName: "Provider",
      width: 90,
    },
    { field: "email", headerName: "Email", width: 240 },
    {
      field: "phone",
      headerName: "Phone",
      width: 140,
    },
    // {
    //   field: "level",
    //   headerName: "Level",
    //   width: 70,
    // },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.first_name || ""} ${params.row.last_name || ""}`,
    // },

    {
      field: "state",
      headerName: "State",
      width: 90,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 90,
      renderCell: (params) => {
        return (
          <>
            <div
              onClick={() => {
                History.push(`/kyc/${params.row.uid}`);
                console.log("OnClick UID: ", params.row.uid);
              }}
            >
              <VisibilityIcon />
            </div>
          </>
        );
      },
    },
  ];

  const rows = users;

  const getExchangeUsers = async (page) => {
    setIsLoading(true);
    if (page === 0) {
      setPage(1);
    }
    try {
      const res = await axios.get(
        `baseAPI/cmrapi/ADMIN/getAllexchangeUsers?urole=${urole}&_sort=date_joined&_order=ASC&page=${page}&_limit=25`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      setUsers(res.data.results);
      setRowCountState(res.data.total_count);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const searchUser = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `baseAPI/cmrapi/ADMIN/search?word=${searchInfo.word}&column=${searchInfo.column}`,

        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      setUsers(res.data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (searchInfo.word.length < 3) {
      getExchangeUsers(page);
    } else {
      searchUser();
    }
  }, [searchInfo.word.length, page, urole]);

  console.log("Length:", searchInfo.word.length);

  return (
    <div className="kyc__body">
      <div className="Relative">
        <div>
          <div className="SelectHandler">
            <small>Search By</small>
            <select
              value={searchInfo.column}
              onChange={(e) =>
                setSearchInfo({ ...searchInfo, column: e.target.value })
              }
            >
              <option value="first_name">First Name</option>
              <option value="username">Username</option>
              <option value="phone">Phone</option>
              <option value="email">Email</option>
              <option value="uid">UID</option>
            </select>
          </div>

          <div className="SelectHandler">
            <small>Filer By Role</small>
            <select value={urole} onChange={(e) => setUrole(e.target.value)}>
              <option value="MEMBER">Member</option>
              <option value="KYCASSISTANT">KYC Assistant</option>
              <option value="ACCOUNTANT">Accountant</option>
              <option value="CUSSERVICE">Customer Service</option>
              <option value="BANKTXASSISTANT">Bank Assistant</option>
              <option value="MANAGEMENT">Management</option>
              <option value="ADMIN">Admin</option>
            </select>
          </div>
        </div>
        <div className="AlignRight">
          <div
            className="search-box"
            style={{ marginTop: "-25px", marginLeft: "200px" }}
          >
            <input
              type="text"
              placeholder="Search User..."
              className="search-input"
              value={searchInfo.word}
              onChange={(e) =>
                setSearchInfo({ ...searchInfo, word: e.target.value })
              }
            />
            <button
              disabled={searchInfo.word.length <= 3}
              className="search-btn"
              onClick={() => {
                searchUser();
              }}
            >
              <SearchOutlined />
            </button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            sx={{ color: "white", backgroundColor: "#191c24", height: "80vh" }}
            rows={rows}
            columns={columns}
            pagination
            {...rowsState}
            paginationMode="server"
            pageSize={25}
            rowsPerPageOptions={[25]}
            page={page - 1}
            rowCount={rowCountState}
            onPageChange={(newPage) => {
              setPage(newPage + 1);
            }}
          />
        </div>
      )}
      {/* <div className="grid-row heading">
        <div className="grid-item first-item">#</div>
        <div className="grid-item grid-span-2">First Name</div>
        <div className="grid-item grid-span-2">Last Name</div>
        <div className="grid-item grid-span-2">Joined Date</div>
        <div className="grid-item grid-span-2">Provider</div>
        <div className="grid-item grid-span-3">Email</div>
        <div className="grid-item grid-span-2">Phone</div>
        <div className="grid-item grid-span-2 last-item">State</div>
      </div>
      {users.map((data, index) => (
        <div
          className="grid-row"
          key={index}
          onClick={() => getUserDetails(data.uid)}
        >
          <div className="grid-item first-item">{index + 1}</div>
          <div className="grid-item grid-span-2">{data.first_name}</div>
          <div className="grid-item grid-span-2">{data.last_name}</div>
          <div className="grid-item grid-span-2">{data.date_joined}</div>
          <div className="grid-item grid-span-2">{data.provider}</div>
          <div className="grid-item grid-span-3">{data.email}</div>
          <div className="grid-item grid-span-2">{data.phone}</div>
          <div className="grid-item grid-span-2 last-item">{data.state}</div>
        </div>
      ))}
      }
      <div className="grid-row">
        <button
          disabled={page == 1}
          onClick={() => {
            setPage(page - 1);
          }}
        >
          Prev
        </button>
        <button
          onClick={() => {
            setPage(page + 1);
          }}
        >
          Next
        </button>
      </div> */}
    </div>
  );
}

export default Users;
