import "../styles/tableRow.scss";

const TableRow = (props) => {
  return (
    <div className={`grid-row ${props.className}`} key={props.key}>
      <div className="row-item">{props.first}</div>
      <div className="row-item">{props.second}</div>
      <div className="row-item">{props.third}</div>
      <div className="row-item">{props.forth}</div>
      <div className="row-item">{props.fifth}</div>
      <div className="row-item">{props.sixth}</div>
      <div className="row-item">{props.seventh}</div>
      <div className="row-item">{props.eighth}</div>
      <div className="row-item">{props.ninth}</div>
      <div className="row-item">{props.tenth}</div>
      <div className="row-item">{props.eleventh}</div>
    </div>
  );
};

export default TableRow;
