import {
  AccountBalance,
  AccountBalanceWallet,
  AddTask,
  CurrencyExchange,
  Dashboard,
  EventNote,
  People,
  RateReview,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import React, { useContext, useState } from "react";
import "../styles/Sidebar.scss";
import FullLogo from "../imgs/FullLogo.png";
import HalfLogo from "../imgs/HalfLogo.png";
import { AuthContext } from "../AuthContext/AuthContext";
import jwt_decode from "jwt-decode";

function Sidebar({ isOpen }) {
  const { user } = useContext(AuthContext);
  const Urole = jwt_decode(user.access).urole;
  console.log("User Role : ", jwt_decode(user.access).urole);

  const Option = ({ name, icon, iconColor, path }) => {
    // const [isSelected, setIsSelected] = useState(false);

    // const toggle = () => {
    //   setIsSelected(!isSelected);
    // };

    return (
      <div
        // onClick={toggle}
        // className={isSelected ? "sidebar__link selected" : "sidebar__link"}
        className="sidebar__link"
      >
        <div className="iconHolder" style={{ color: iconColor }}>
          {icon}
        </div>
        <div className="name">
          <p>{name}</p>
        </div>
      </div>
    );
  };
  return (
    <div className={isOpen ? "sidebar" : "sidebar sidebar__collapsed"}>
      <div className="sidebar__logo">
        <img className="full_logo" src={FullLogo} alt="" />
        <img className="half_logo" src={HalfLogo} alt="" />
      </div>
      <div className="sidebar__account"></div>
      <div className="sidebar__optionHeader">
        <h5>Navigation</h5>
      </div>
      {Urole === "ADMIN" ? (
        <div className="sidebar__options">
          <Link to="/home">
            <Option icon={<Dashboard />} name="Dashboard" iconColor="purple" />
          </Link>
          <Link to="/users">
            <Option icon={<People />} name="Users" iconColor="White" />
          </Link>
          <Link to="/kycassistant/manualreviews">
            <Option
              icon={<RateReview />}
              name="Manual Reviews"
              iconColor="blue"
            />
          </Link>
          <Link to="/currency">
            <Option
              icon={<CurrencyExchange />}
              name="Currency Data"
              iconColor="Yellow"
            />
          </Link>
          <Link to="/banking">
            <Option
              icon={<AccountBalance />}
              name="Banking"
              iconColor="purple"
            />
          </Link>
          <Link to="/banking/withdrawStatus">
            <Option
              icon={<AddTask />}
              name="Withdraw Status"
              iconColor="white"
            />
          </Link>
          <Link to="/banking/depositStatus">
            <Option
              icon={<AccountBalanceWallet />}
              name="Deposit Status"
              iconColor="Blue"
            />
          </Link>
          <Link to="/order">
            <Option
              icon={<EventNote />}
              name="Order Management"
              iconColor="Yellow"
            />
          </Link>
        </div>
      ) : (
        <></>
      )}
      {Urole === "KYCASSISTANT" ? (
        <div className="sidebar__options">
          <Link to="/kycassistant/manualreviews">
            <Option icon={<Dashboard />} name="Dashboard" iconColor="purple" />
          </Link>
        </div>
      ) : (
        <></>
      )}
      {Urole === "MANAGEMENT" ? (
        <div className="sidebar__options">
          <Link to="/currency">
            <Option
              icon={<CurrencyExchange />}
              name="Currency Data"
              iconColor="Yellow"
            />
          </Link>
        </div>
      ) : (
        <></>
      )}
      {Urole === "BANKTXASSISTANT" ? (
        <div className="sidebar__options">
          <Link to="/banking/withdrawStatus">
            <Option
              icon={<AddTask />}
              name="Withdraw Status"
              iconColor="white"
            />
          </Link>
          <Link to="/banking/depositStatus">
            <Option
              icon={<AccountBalanceWallet />}
              name="Deposit Status"
              iconColor="Blue"
            />
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Sidebar;
