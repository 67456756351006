import axios from "axios";
import { loginFailure, loginSuccess, loginStart } from "./AuthActions";
export const login = async (user, dispatch) => {
  dispatch(loginStart());
  try {
    const res = await axios.post(
      "gateWayAPI/mob/api/v4/auth/login/email/",
      user,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Cross-Origin-Opener-Policy": "same-origin",
          "Referrer-Policy": "same-origin",
        },
      }
    );
    dispatch(loginSuccess(res.data));
  } catch (err) {
    dispatch(loginFailure());
  }
};
