import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../AuthContext/AuthContext";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "../../styles/kycAssistant/landing.scss";
import TableRow from "../../Components/TableRow";
import axios from "axios";
import Modal from "../../Components/Modal";
import KycReview from "../../Components/KYCReview/KycReview";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { formatDate } from "../../utils/DataFormat";

function ManualReviews() {
  const [mReviewData, setMReviwData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const [userID, setUserID] = useState({});

  const [approvedData, setApprovedData] = useState({});
  const [rejectedData, setRejectedData] = useState({});

  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);

  const History = useHistory();

  const location = useLocation();

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(async () => {
    await sleep(3000);
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, []);

  useEffect(() => {
    if (
      jwt_decode(user.access).urole != "ADMIN" &&
      jwt_decode(user.access).urole != "KYCASSISTANT"
    ) {
      History.push("/");
    } else {
      return;
    }
  }, [user.access, History]);

  // https://ampiycma.ampiy.com/cmrapi/KYCASSISTANT/getmReview/

  useEffect(() => {
    const getKYCInfo = async () => {
      try {
        const res = await axios.get(
          `../baseAPI/cmrapi/KYCASSISTANT/getmReview?_sort=created_at&_order=DESC&page=1&_limit=25`,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("user")).access
              }`,
            },
          }
        );
        setMReviwData(res.data.mReviewData);
      } catch (err) {
        console.log(err);
      }
    };
    getKYCInfo();
  }, [approvedData]);

  const approveKYCDocs = async (userData) => {
    try {
      const res = await axios.post(
        "../baseAPI/cmrapi/KYCASSISTANT/approveKycDoc/",
        { user_id: userData.user_id, id: userData.id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("Response:", res);
      if (res.status == 200) {
        swal(
          "User Approved!",
          "Selected User's KYC documents are approved.",
          "success"
        );
        setApprovedData(res.data.updateStatusDoc[1][0]);
      }
      setIsModalOpen(false);
      setIsApproved(true);
    } catch (err) {
      console.log(err);
      setIsModalOpen(false);
    }
  };

  const rejectKYCDocs = async (userData, reason) => {
    try {
      const res = await axios.post(
        "../baseAPI/cmrapi/KYCASSISTANT/rejectKycDocs/",
        { user_id: userData.user_id, id: userData.id, reason: reason },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (res.status === 200) {
        swal(
          "User Rejected!",
          "Selected User's KYC documents are rejected.",
          "warning"
        );
      }
      setRejectedData(res.data);
      setIsModalOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const ApprovedUser = () => {
    return (
      <>
        <div className="approvedUser">
          <h2>
            User
            <br /> Approved
          </h2>
          <div className="approvedUser_info">
            <p>
              <b>Doc ID :</b>
              <span>{approvedData.id}</span>
            </p>
            <p>
              <b>Name :</b>
              <span>{""}</span>
            </p>
            <p>
              <b>Doc Type : </b>
              <span>{approvedData.doc_type}</span>
            </p>
            <p>
              <b>Doc Issue :</b>

              <span>{approvedData.doc_issue}</span>
            </p>
            <p>
              <b>Doc Expire :</b>

              <span>{approvedData.doc_expire}</span>
            </p>
            <p>
              <b>Reviewed By :</b>
              <span>{approvedData.reviewed_by}</span>
            </p>
            <p>
              <b>Updated At :</b>
              <span>{approvedData.updatedAt}</span>
            </p>
          </div>
          <button
            onClick={() => {
              setIsApproved(false);
            }}
          >
            OK
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="landing">
        <div className="TableContainer">
          <TableRow
            className="grid-cols-5 head"
            first="ID"
            second="Doc ID"
            third="Doc Type"
            forth="Created At"
            fifth="Action"
          />
          {mReviewData.map((data, index) => (
            <div className="MapContainer" key={index}>
              <TableRow
                className="grid-cols-5"
                first={data?.id}
                second={data?.doc_id}
                third={data?.doc_type.toUpperCase()}
                forth={formatDate(data.created_at?.slice(0, 10))}
                fifth={
                  <button
                    className="actionBtn"
                    onClick={() => {
                      setUserID(data);
                      setIsModalOpen(true);
                    }}
                  >
                    View Details
                  </button>
                }
              />
            </div>
          ))}
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <KycReview
          userData={userID}
          Approve={() => {
            swal({
              title: "Are you sure?",
              text: "Once Approved, you will not be able to review it again!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
              className: "sweetalert",
            }).then((value) => {
              if (value) {
                approveKYCDocs(userID);
              } else {
                swal("Review is not Approved!", {
                  icon: "warning",
                  className: "sweetalert",
                });
              }
            });
          }}
          Reject={(userID, reason) => {
            swal({
              title: "Are you sure?",
              text: "Once Rejected, you will not be able to review it again!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
              className: "sweetalert",
            }).then((value) => {
              if (value) {
                rejectKYCDocs(userID, reason);
              } else {
                swal("Review is not Rejected!", {
                  icon: "warning",
                  className: "sweetalert",
                });
              }
            });
          }}
        />
      </Modal>
      <Modal
        open={isApproved}
        onClose={() => {
          setIsApproved(false);
        }}
      >
        <ApprovedUser />
      </Modal>
    </>
  );
}

export default ManualReviews;
