import "./navbar.scss";
import { Email, Menu, Apps } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { logout } from "../../AuthContext/AuthActions";
import { AuthContext } from "../../AuthContext/AuthContext";
import { useContext } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

const Navbar = ({ Toggle }) => {
  const { user } = useContext(AuthContext);
  const Urole = jwt_decode(user.access).urole;
  const History = useHistory();

  const restartGateway = async () => {
    try {
      const res = await axios.get("baseAPI/cmrapi/ADMIN/restartGateway/", {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user")).access
          }`,
        },
      });
      console.log("GateWay Restart :", res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const { dispatch } = useContext(AuthContext);
  return (
    <div className="navbar">
      <div className="nav__left">
        <div
          className="sidebar__toggle"
          onClick={() => {
            Toggle();
          }}
        >
          <Menu />
        </div>
        <input type="text" placeholder="Search User" />
      </div>
      <div className="nav__right">
        {Urole === "ADMIN" ? (
          <button
            onClick={() => {
              restartGateway();
            }}
          >
            Restart Gateway
          </button>
        ) : (
          <></>
        )}
        <div className="nav__icons">
          <Email />
          <hr />
          <Email />
          <hr />
          <div className="link">
            <Link to="/">
              <Apps />
            </Link>
          </div>
        </div>
        <div className="nav__account">
          <Avatar />
          <p>{jwt_decode(user.access).name}</p>
          <div className="account__info">
            <Avatar />
            <br />
            <p>{jwt_decode(user.access).name}</p>
            <br />
            <p>
              <small>{jwt_decode(user.access).urole}</small>
            </p>
            <div className="border"></div>
            <div className="navbar__options">
              <div className="navbar__option">Setting</div>
              <div
                className="navbar__option"
                onClick={() => {
                  dispatch(logout());
                  History.push("/");
                }}
              >
                Lagout
              </div>
              <Link className="link" to="/">
                <div className="navbar__option">App Selection</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
