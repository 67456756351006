/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../AuthContext/AuthContext";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "../../Components/Modal";
import NewDeposit from "../../Components/Modal/newDeposit";
import Loading from "../../Components/Loading/Loading";
import { SearchOutlined } from "@mui/icons-material";
import {
  formatDate,
  nullCheck,
  transferTypeCheck,
  typeCheck,
} from "../../utils/DataFormat";
import swal from "sweetalert";

function DepositStatus() {
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [depositData, setDepositData] = useState([]);
  const [selectedWithdrawData, setSelectedWithdrawData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isDepositWindowOpen, setIsDepositWindowOpen] = useState(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [filterData, setFilterData] = useState({
    status: "pending",
  });
  const [page, setPage] = useState(1);

  const { user } = useContext(AuthContext);
  const History = useHistory();

  const location = useLocation();

  useEffect(() => {
    const sleep = (milliseconds) => {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };
    sleep(3000).then(() => {
      const Pathname = location.pathname;
      localStorage.setItem("Pathname", Pathname);
    });
  }, []);

  useEffect(() => {
    if (
      jwt_decode(user.access).urole !== "BANKXASSISTANT" &&
      jwt_decode(user.access).urole !== "ADMIN"
    ) {
      History.push("/");
    } else {
      return;
    }
  }, [user.access, History]);

  useEffect(() => {
    const getDepositTableInfo = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `../baseAPI/cmrapi//ADMIN/getDepositTable?_sort=createdAt&_order=DESC&page=${page}&_limit=25&_status=${filterData.status}`,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("user")).access
              }`,
            },
          }
        );
        setDepositData(res.data.depositData);
        setRowCountState(res.data.count);
        setRefresh(false);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getDepositTableInfo();

    if (refresh === true) {
      getDepositTableInfo();
    }
  }, [page, refresh, filterData.status]);

  const rejectDepositStatus = async () => {
    try {
      const res = await axios.post(
        `../baseAPI/cmrapi/ADMIN/rejectDeposit?id=${selectedWithdrawData.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      setRefresh(true);
      if (res.status === 200) {
        swal("Deposit has Been Approved!", {
          icon: "success",
          className: "sweetalert",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const approveDepositStatus = async () => {
    try {
      const res = await axios.post(
        `../baseAPI/cmrapi/ADMIN/approveDeposit?id=${selectedWithdrawData.id}&Mid=${selectedWithdrawData.member_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      if (res.status === 200) {
        swal("Deposit has Been Approved!", {
          icon: "success",
          className: "sweetalert",
        });
      }
      setRefresh(true);
    } catch (err) {
      console.log(err);
    }
  };

  const DepositDataModal = React.forwardRef(() => {
    const tableRowList = [
      { id: "id", label: "ID" },
      { id: "tid", label: "TID" },
      { id: "txid", label: "Tx ID" },
      { id: "uid", label: "UID" },
      { id: "member_id", label: "Member ID" },
      { id: "creator_id", label: "Creator ID" },
      { id: "reviewed_by", label: "Reviewed By" },
      { id: "txout", label: "Tx Out" },
      { id: "aasm_state", label: "State" },
      { id: "currency_id", label: "Currency" },
      { id: "block_number", label: "Block Number" },
      { id: "transfer_type", label: "Transfer Type" },
      { id: "type", label: "Type" },
      { id: "reference_no", label: "Meta Reference No", type: "meta" },
      { id: "account_no", label: "Meta Account No", type: "meta" },
      { id: "name", label: "Meta Name", type: "meta" },
      { id: "bank_name", label: "Meta Bank Name", type: "meta" },
      { id: "transfer_description", label: "Meta Description", type: "meta" },
      { id: "fee", label: "Fee" },
      { id: "amount", label: "Amount" },
      { id: "address", label: "Address" },
      { id: "from_addresses", label: "From Addresses" },
      { id: "spread", label: "Spread" },
      { id: "completed_at", label: "Completed At" },
      { id: "updated_at", label: "Updated At" },
      { id: "created_at", label: "Created At" },
    ];

    const renderLogic = ({ id, type }) => {
      if (["completed_at", "updated_at", "created_at"].includes(id))
        return formatDate(selectedWithdrawData[id]);
      if (id === "transfer_type")
        return transferTypeCheck(selectedWithdrawData[id]);
      if (id === "type") return typeCheck(selectedWithdrawData[id]);
      if (type === "meta") return nullCheck(selectedWithdrawData?.meta[id]);
      return nullCheck(selectedWithdrawData[id]);
    };
    return (
      <div className="tab-container" style={{ width: 600 }}>
        <h1>Deposit Details</h1>
        <div className="MainBody">
          <div className="verticalScroll">
            <table style={{ width: "100%", textAlign: "left" }}>
              <tbody>
                {tableRowList.map((row) => (
                  <tr key={row.id}>
                    <th>{row.label}</th>
                    <td>
                      <p>:&nbsp;{renderLogic(row)}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={
            selectedWithdrawData.aasm_state === "accepted" ||
            selectedWithdrawData.aasm_state === "rejected"
              ? "None"
              : "Btns"
          }
          // className="Btns"
        >
          <button
            className="approve_btn"
            onClick={async () => {
              swal({
                title: "Are you sure?",
                text: "Once Approved, you will not be able to recover the deposit!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                className: "sweetalert",
              }).then((value) => {
                if (value) {
                  approveDepositStatus();
                } else {
                  swal("Deposit is not Approved!", {
                    icon: "warning",
                    className: "sweetalert",
                  });
                }
              });
            }}
          >
            Approve
          </button>
          <button
            className="reject_btn"
            onClick={() => {
              swal({
                title: "Are you sure?",
                text: "Once Rejected, you will not be able to recover the deposit!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                className: "sweetalert",
              }).then((value) => {
                if (value) {
                  rejectDepositStatus();
                } else {
                  swal("Deposit is not Rejected!", {
                    icon: "warning",
                    className: "sweetalert",
                  });
                }
              });
            }}
          >
            Reject
          </button>
        </div>
      </div>
    );
  });

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: "180",
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => {
                setIsOpen(true);
                setSelectedWithdrawData(params.row);
                console.log("Index", params.row);
              }}
            >
              View Details
            </button>
          </>
        );
      },
    },
    { field: "id", headerName: "ID", width: "70" },
    { field: "txid", headerName: "Tx ID", width: "150" },
    // { field: "createdAt", headerName: "Created At", width: "200" },
    // { field: "error", headerName: "Error", width: "90" },
    { field: "amount", headerName: "Amount", width: "190" },
    { field: "currency_id", headerName: "Currency", width: "90" },
    { field: "fee", headerName: "Fee", width: "150" },
    { field: "confirmation", headerName: "Confirmation", width: "150" },
    { field: "aasm_state", headerName: "State", width: "150" },
    // { field: "", headerName: "H", width: "90" },
  ];

  return (
    <>
      <div className="kyc__body">
        <div
          className="orderSorter"
          style={{
            justifyContent: "space-between",
          }}
        >
          <div className="selectHolder">
            <small>Status</small>
            <select
              value={filterData.status}
              onChange={(e) =>
                setFilterData({ ...filterData, status: e.target.value })
              }
            >
              <option value="wait">Wait</option>
              <option value="fail">Failed</option>
              <option value="done">Done</option>
              <option value="pending">Pending</option>
            </select>
          </div>

          <div className="AlignRight" style={{ position: "relative" }}>
            <div className="search-box">
              <input
                type="text"
                placeholder="Search User..."
                className="search-input"
              />
              <span className="search-btn">
                <SearchOutlined />
              </span>
            </div>
            <button
              style={{ float: "right" }}
              onClick={() => setIsDepositWindowOpen(true)}
            >
              Add Deposit Record
            </button>
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              sx={{
                color: "white",
                backgroundColor: "#191c24",
                height: "80vh",
              }}
              rows={depositData === null ? [] : depositData}
              columns={columns}
              pagination
              paginationMode="server"
              pageSize={25}
              rowsPerPageOptions={[25]}
              page={page - 1}
              rowCount={Number(rowCountState)}
              onPageChange={(newPage) => {
                setPage(newPage + 1);
              }}
            />
          </div>
        )}
      </div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <DepositDataModal />
      </Modal>
      <Modal
        open={isDepositWindowOpen}
        onClose={() => setIsDepositWindowOpen(false)}
      >
        <NewDeposit onClose={() => setIsDepositWindowOpen(false)} />
      </Modal>
    </>
  );
}

export default DepositStatus;
