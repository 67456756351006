import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../AuthContext/AuthContext";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import "./WithdrawStatus.scss";
import Modal from "../../Components/Modal";
import Confirmation from "../../Components/Modal/Confirmation";
import Loading from "../../Components/Loading/Loading";
import {
  formatDate,
  nullCheck,
  nullCheckArray,
  transferTypeCheck,
  typeCheck,
} from "../../utils/DataFormat";
import swal from "sweetalert";

function WithdrawStatus() {
  const [isLoading, setIsLoading] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [withdrawData, setWithdrawData] = useState({});
  const [selectedWithdrawData, setSelectedWithdrawData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [rowCountState, setRowCountState] = useState();
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 25,
  });
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState({
    status: "initiated",
  });

  const { user } = useContext(AuthContext);
  const History = useHistory();

  const location = useLocation();

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(async () => {
    await sleep(3000);
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, []);

  useEffect(() => {
    if (
      jwt_decode(user.access).urole != "BANKXASSISTANT" &&
      jwt_decode(user.access).urole != "ADMIN"
    ) {
      History.push("/");
    } else {
      return;
    }
  }, [user.access, History]);

  useEffect(() => {
    const getWithDrawTableInfo = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `../baseAPI/cmrapi//ADMIN/getWithdrawTable?_sort=createdAt&_order=DESC&page=${page}&_limit=25&_status=${filterData.status}`,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("user")).access
              }`,
            },
          }
        );
        setWithdrawData(res.data.withdrawData);
        setRowCountState(res.data.count);
        setRefresh(false);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getWithDrawTableInfo();

    if (refresh === true) {
      getWithDrawTableInfo();
    }
  }, [page, refresh, filterData.status]);

  const rejectWithdrawStatus = async () => {
    try {
      const res = await axios.post(
        `../baseAPI/cmrapi/ADMIN/rejectWithdraw?id=${selectedWithdrawData.id}&Mid=${selectedWithdrawData.member_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      setRefresh(true);
      if (res.status === 200) {
        swal("Withdrawal has Been Approved!", {
          icon: "success",
          className: "sweetalert",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const approveWithdrawStatus = async () => {
    try {
      const res = await axios.post(
        `../baseAPI/cmrapi/ADMIN/approveWithdraw?id=${selectedWithdrawData.id}&Mid=${selectedWithdrawData.member_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      if (res.status === 200) {
        swal("Withdrawal has Been Approved!", {
          icon: "success",
          className: "sweetalert",
        });
      }
      setRefresh(true);
    } catch (err) {
      console.log(err);
    }
  };

  console.log("WithDraw Data :", withdrawData);
  console.log("Is Open : ", isOpen);
  console.log("Modal Data:", selectedWithdrawData);

  const ViewWithdrawDetails = async (txid) => {
    try {
      const res = await axios.get(
        `../baseAPI/cmrapi/ADMIN/getWithDrawDetail/?id=${txid}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      if (res.status === 200) {
        setSelectedWithdrawData(res.data?.data);
        console.log("View Details : ", res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const WithdrawDataModal = () => {
    return (
      <div className="tab-container" style={{ width: "50vw" }}>
        <h1>Withdraw Details</h1>
        {selectedWithdrawData.map((item) => (
          <div className="tab-container" style={{ width: "93%", margin: 0 }}>
            <div className="MainBody" style={{ width: "100%", margin: 0 }}>
              <div className="verticalScroll">
                <table style={{ width: "100%", textAlign: "left" }}>
                  <tr>
                    <td>ID</td>
                    <td>
                      <p>:&nbsp;{item.id}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>
                      <p>:&nbsp;{item.name}</p>
                    </td>
                  </tr>
                  <tr>
                    {nullCheckArray(item.bankdetails).map((item) => (
                      <>
                        <td>Bank Deatils </td>
                        <td>
                          {/* <p>:&nbsp;{item.name}</p> */}
                          &nbsp;
                          <details style={{ margin: "20px" }}>
                            <p>
                              Account holder Name: <br />
                              {item?.acchol_name}
                            </p>
                            <p>
                              Account Number :<br />
                              {item?.accnum}
                            </p>
                            <p>
                              Account Alias:
                              <br />
                              {item?.alias}
                            </p>
                            <p>
                              Account ISFC:
                              <br />
                              {item?.isfc}
                            </p>
                            <p>
                              Account Type :<br />
                              {item?.type}
                            </p>
                            <p>
                              UPI ID : <br />
                              {item?.upiid}
                            </p>
                          </details>
                        </td>
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td>Member ID</td>
                    <td>
                      <p>:&nbsp;{item.member_id}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Tx ID</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.txid == null ? "-" : item.txid}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Fee</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.fee == null ? "-" : item.fee}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.amount == null ? "-" : item.amount}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Created At</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.created_at == null
                          ? "-"
                          : formatDate(item.created_at)}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Currency</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.currency_id == null ? "-" : item.currency_id}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Meta Data</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.metadata == null ? "-" : item.metadata}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Beneficiary ID</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.beneficiary_id == null
                          ? "-"
                          : item.beneficiary_id}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>State</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.aasm_state == null ? "-" : item.aasm_state}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Bank ID</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.bank_id == null ? "-" : item.bank_id}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Block Number</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.block_number == null ? "-" : item.block_number}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Completed At</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.completed_at == null ? "-" : item.completed_at}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Error</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.error == null ? "-" : item.error}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Note</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.note == null ? "-" : item.note}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Reason </td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.reason == null ? "-" : item.reason}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Reviewed By</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.reviewed_by == null ? "-" : item.reviewed_by}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Rid</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.rid == null ? "-" : item.rid}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Sum</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.sum == null ? "-" : item.sum}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>TID</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.tid == null ? "-" : item.tid}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Transfer Type</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.transfer_type == null
                          ? "-"
                          : transferTypeCheck(item.transfer_type)}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>
                      <p>:&nbsp;{nullCheck(typeCheck(item.type))}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Updated At</td>
                    <td>
                      <p>
                        :&nbsp;
                        {item.updated_at == null
                          ? "-"
                          : formatDate(item.updated_at)}
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div
              className={
                item.aasm_state === "cancelled" ||
                item.aasm_state === "wait4otp"
                  ? "None"
                  : "Btns"
              }
            >
              <button
                className="approve_btn"
                onClick={() => {
                  swal({
                    title: "Are you sure?",
                    text: "Once Approved, you will not be able to recover the deposit!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    className: "sweetalert",
                  }).then((value) => {
                    if (value) {
                      approveWithdrawStatus();
                    } else {
                      swal("Deposit is not Approved!", {
                        icon: "warning",
                        className: "sweetalert",
                      });
                    }
                  });
                }}
              >
                Approve
              </button>
              <button
                className="reject_btn"
                onClick={() => {
                  swal({
                    title: "Are you sure?",
                    text: "Once Rejected, you will not be able to recover the Withdrawal!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    className: "sweetalert",
                  }).then((value) => {
                    if (value) {
                      rejectWithdrawStatus();
                    } else {
                      swal("Withdrawal is not Rejected!", {
                        icon: "warning",
                        className: "sweetalert",
                      });
                    }
                  });
                }}
              >
                Reject
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: "180",
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => {
                ViewWithdrawDetails(params.row.txid);
                setIsOpen(true);
                // setSelectedWithdrawData(params.row);
              }}
            >
              View Details
            </button>
          </>
        );
      },
    },
    { field: "id", headerName: "ID", width: "70" },
    { field: "txid", headerName: "Tx ID", width: "150" },
    // { field: "createdAt", headerName: "Created At", width: "200" },
    // { field: "error", headerName: "Error", width: "90" },
    { field: "amount", headerName: "Amount", width: "190" },
    { field: "currency_id", headerName: "Currency", width: "90" },
    { field: "fee", headerName: "Fee", width: "150" },
    { field: "confirmation", headerName: "Confirmation", width: "150" },
    { field: "aasm_state", headerName: "State", width: "150" },
    // { field: "", headerName: "H", width: "90" },
  ];

  return (
    <>
      <div className="kyc__body">
        <div className="orderSorter">
          <div className="selectHolder">
            <small>Status</small>
            <select
              value={filterData.status}
              onChange={(e) =>
                setFilterData({ ...filterData, status: e.target.value })
              }
            >
              <option value="wait4otp">Wait For OTP</option>
              <option value="processing">Processing</option>
              <option value="rejected">Rejected</option>
              <option value="cancelled">Cancelled</option>
              <option value="initiated">Withdraw initiated</option>
              <option value="completed">Completed</option>
            </select>
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              sx={{
                color: "white",
                backgroundColor: "#191c24",
                height: "80vh",
              }}
              rows={withdrawData === null || undefined ? {} : withdrawData}
              columns={columns}
              pagination
              {...rowsState}
              paginationMode="server"
              pageSize={25}
              rowsPerPageOptions={[25]}
              page={page - 1}
              rowCount={rowCountState}
              onPageChange={(newPage) => {
                setPage(newPage + 1);
              }}
            />
          </div>
        )}
      </div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <WithdrawDataModal />
      </Modal>
    </>
  );
}

export default WithdrawStatus;
