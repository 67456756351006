import React, { useContext, useEffect, useState } from "react";
import "../styles/login.scss";
import { login } from "../AuthContext/apiCalls";
import { AuthContext } from "../AuthContext/AuthContext";
import FullLogo from "../imgs/FullLogo.png";
import { checkBlank, validateEmail } from "../utils/validations";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorObj, setErrorObj] = useState({
    email: " ",
    password: " ",
  });

  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    localStorage.setItem("userDetails", null);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (handleValidation()) return;
    setIsLoading(true);
    await login({ email, password }, dispatch);
    setIsLoading(false);
  };

  const handleValidation = () => {
    let isError = false;
    if (checkBlank([email, password])) {
      setErrorObj({
        email: "Email cannot be blank",
        password: "Password cannot be blank",
      });
      isError = true;
    } else if (!validateEmail(email)) {
      setErrorObj({
        email: "Please enter a valid email",
        password: " ",
      });
      isError = true;
    }
    return isError;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login">
      <div className="logo__section">
        <img className="logo" src={FullLogo} alt="" />
      </div>

      <form>
        <h2>LOGIN</h2>
        <TextField
          disabled={isLoading}
          className="login-input"
          margin="normal"
          fullWidth
          label="Email"
          helperText={errorObj.email}
          error={errorObj.email ? true : false}
          variant="standard"
          size="medium"
          type="email"
          required
          value={email}
          onChange={(e) => {
            setErrorObj((oldState) => {
              return { ...oldState, email: " " };
            });
            setEmail(e.target.value);
          }}
        />
        <TextField
          disabled={isLoading}
          className="login-input"
          margin="normal"
          fullWidth
          label="Password"
          variant="standard"
          helperText={errorObj.password}
          error={errorObj.password ? true : false}
          type={showPassword ? "text" : "password"}
          size="medium"
          value={password}
          required
          onChange={(e) => {
            setErrorObj((oldState) => {
              return { ...oldState, password: " " };
            });
            setPassword(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    backgroundColor: "transparent !important",
                    color: "#c1165f !important",
                  }}
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  onMouseDown={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <label>Email:</label>
        <br />
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <label>Password:</label>
        <br />
        <input
          type="password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <br /> */}

        <Button onClick={handleLogin}>
          {isLoading ? (
            <CircularProgress size={24} thickness={3} sx={{ color: "white" }} />
          ) : (
            "Login"
          )}
        </Button>
      </form>
    </div>
  );
}

export default Login;
