import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext/AuthContext";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import "../styles/kyc.scss";
import TableRow from "../Components/TableRow";

import { useLocation, useParams } from "react-router-dom";
import Loading from "../Components/Loading/Loading";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { nullCheck, nullCheckArray } from "../utils/DataFormat";
import swal from "sweetalert";

function KYCpage() {
  const [isLoading, setIsLoading] = useState(false);

  const [tab, setTab] = useState("MainDetails");

  const [mid, setMid] = useState();
  const [usrId, setUsrID] = useState();

  const [mainData, setMainData] = useState({});
  const [personalInfo, setPersonalInfo] = useState({
    username: "",
    uid: "",
    first_name: "",
    last_name: "",
    provider: "",
    phone: "",
    email: "",
    urole: "",
  });
  const [kycDetails, setKYCDetails] = useState([]);
  //    {
  //    doc_type: "",
  //    doc_id: "",
  //    doc_issue: "",
  //    doc_expire: "",
  //    doc_category: "",
  //    doc_front: "",
  //    doc_back: "",
  //  }
  const [orderData, setOrderData] = useState([]);
  const [balanceData, setBalanceData] = useState([]);
  const [activities, setActivities] = useState([]);
  const [tradeTableData, setTradeTableData] = useState([]);
  const [orderTableData, setOrderTableData] = useState([]);
  const [depositTableData, setDepositTableData] = useState([]);
  const [withdrawTableData, setWithdrawTableData] = useState([]);

  const { user } = useContext(AuthContext);

  const { uid } = useParams();

  const History = useHistory();

  const location = useLocation();

  console.log("Location :", location);

  console.log("UID :", uid);
  console.log("Mid : ", mid);
  console.log("User ID: ", usrId);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(async () => {
    await sleep(3000);
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, []);

  useEffect(() => {
    if (jwt_decode(user.access).urole != "ADMIN") {
      History.push("/");
    } else {
    }
  }, [user.access, History]);

  useEffect(() => {
    const getPersonalInfo = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(`../baseAPI/cmrapi/ADMIN/userdata/${uid}`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        });
        setMainData(res.data);
        setPersonalInfo(res.data.userData);
        setUsrID(res.data.userData.id);
        res.data.memberData === null || setMid(res.data.memberData.id);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getPersonalInfo();
  }, []);

  const getKYCDetails = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `../baseAPI/cmrapi//ADMIN/userKycData?uid=${uid}&UsrId=${usrId}&Mid=${mid}`,
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        }
      );
      setKYCDetails(res.data.document);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  console.log("Data Check : ", kycDetails);

  const getOrderInfo = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `../baseAPI/cmrapi//ADMIN/userOrders/${uid}/${usrId}?member_id=${mid}&_sort=createdAt&_order=DESC&page=1&_limit=25`,
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        }
      );
      setOrderData(res.data.orderData);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getBalanceInfo = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `../baseAPI/cmrapi//ADMIN/getBalances/${uid}/${usrId}/${mid}`,
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        }
      );
      setBalanceData(res.data.balanceData);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getActivitiesInfo = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `../baseAPI/cmrapi/ADMIN/getuserActivities/?uid=${uid}&_sort=created_at&_order=DESC&page=2&_limit=5`,
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        }
      );
      setIsLoading(false);
      setActivities(res.data.userActivityData);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  // https://ampiycma.ampiy.com/cmrapi/ADMIN/getTradeTable/
  const getTableInfo = async () => {
    setIsLoading(true);
    const getTradeTableInfo = async () => {
      try {
        const res = await axios.get(
          `../baseAPI/cmrapi//ADMIN/getTradeTable?member_id=${mid}&_sort=createdAt&_order=DESC&page=1&_limit=25`,
          {
            headers: {
              Authorization: `Bearer ${user.access}`,
            },
          }
        );
        setTradeTableData(res.data.tradeData);
      } catch (err) {
        console.log(err);
      }
    };

    const getOrderTableInfo = async () => {
      try {
        const res = await axios.get(
          `../baseAPI/cmrapi//ADMIN/getOrderTable?member_id=${mid}&_sort=createdAt&_order=DESC&page=1&_limit=25`,
          {
            headers: {
              Authorization: `Bearer ${user.access}`,
            },
          }
        );
        setOrderTableData(res.data.orderData);
      } catch (err) {
        console.log(err);
      }
    };
    const getDepositTableInfo = async () => {
      try {
        const res = await axios.get(
          `../baseAPI/cmrapi//ADMIN/getDepositTable?member_id=${mid}&_sort=createdAt&_order=DESC&page=1&_limit=25`,
          {
            headers: {
              Authorization: `Bearer ${user.access}`,
            },
          }
        );
        setDepositTableData(res.data.depositData);
      } catch (err) {
        console.log(err);
      }
    };
    const getWithDrawTableInfo = async () => {
      try {
        const res = await axios.get(
          `../baseAPI/cmrapi//ADMIN/getWithdrawTable?member_id=${mid}&_sort=createdAt&_order=DESC&page=1&_limit=25`,
          {
            headers: {
              Authorization: `Bearer ${user.access}`,
            },
          }
        );
        setWithdrawTableData(res.data.withdrawData);
      } catch (err) {
        console.log(err);
      }
    };

    getTradeTableInfo();
    getOrderTableInfo();
    getDepositTableInfo();
    getWithDrawTableInfo();
    setIsLoading(false);
  };

  return (
    <div className="kyc">
      <div className="Container">
        <div className="Operations">
          <button
            className="main_Details"
            onClick={() => {
              setTab("MainDetails");
            }}
          >
            Personal Info
          </button>
          <button
            className={(mid === undefined || mid === null) && "disabled"}
            onClick={() => {
              setTab("KYC");
              getKYCDetails();
            }}
            disabled={mid === undefined || null}
          >
            KYC
          </button>
          <button
            className={(mid === undefined || mid === null) && "disabled"}
            onClick={() => {
              setTab("OpenOrders");
              getOrderInfo();
            }}
            disabled={mid === undefined || null}
          >
            Open Orders
          </button>
          <button
            className={(mid === undefined || mid === null) && "disabled"}
            onClick={() => {
              setTab("Balance");
              getBalanceInfo();
            }}
            disabled={mid === undefined || null}
          >
            Balance
          </button>
          <button
            className={(mid === undefined || mid === null) && "disabled"}
            onClick={() => {
              setTab("History");
              getTableInfo();
            }}
            disabled={mid === undefined || null}
          >
            History
          </button>
          <button
            className="ActivitiesBtn"
            onClick={() => {
              setTab("Activity");
              getActivitiesInfo();
            }}
          >
            Activities
          </button>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="MainBody">
            {tab == "MainDetails" ? (
              <>
                <h2>Personal Info</h2>
                <p>
                  <b>Username : </b>
                  {personalInfo.username}
                </p>
                <p>
                  <b>UID : </b>
                  {personalInfo.uid}
                </p>
                <p>
                  <b>Name : </b>
                  {personalInfo.first_name + " " + personalInfo.last_name}
                </p>
                <p>
                  <b>Provider : </b>
                  {personalInfo.provider}
                </p>
                <p>
                  <b>Phone : </b>
                  {personalInfo.phone === null
                    ? "Not Available"
                    : personalInfo.phone}
                </p>
                <p>
                  <b>Email : </b>
                  {personalInfo.email === null
                    ? "Not Available"
                    : personalInfo.email}
                </p>
                <p>
                  <b>Role : </b>
                  {personalInfo.urole}
                </p>
              </>
            ) : (
              <></>
            )}
            {tab == "KYC" ? (
              <>
                <h1>KYC Document</h1>
                {kycDetails.map((item, index) => (
                  <>
                    <p>
                      <b>Document :</b>
                      {item.doc_type}
                    </p>
                    <p>
                      <b>Document ID :</b>
                      {item.doc_id}
                    </p>
                    <p>
                      <b>Document Issue Date :</b>
                      {item.doc_issue}
                    </p>
                    <p>
                      <b>Document Expiry Date :</b>
                      {item.doc_expire}
                    </p>
                    <p>
                      <b>Document Category :</b>
                      {item.doc_category}
                    </p>
                    <p>
                      <b>Document Front :</b>
                      <Zoom>
                        <img src={item.doc_front} alt={item.doc_front} />
                      </Zoom>
                    </p>
                    <p>
                      <b>Document Back :</b>
                      <br />
                      <Zoom>
                        <img src={item.doc_back} alt="" />
                      </Zoom>
                    </p>
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
            {tab == "OpenOrders" ? (
              <>
                <h1>Open Order</h1>
                <div className="flex-container">
                  <div className="scrollHandler">
                    <TableRow
                      className="grid-cols-10 head"
                      first="Order ID"
                      second="Market"
                      third="Type"
                      forth="Amount"
                      fifth="Executed"
                      sixth="Price"
                      seventh="Average"
                      eighth="Side"
                      ninth="Created"
                      tenth="Uploaded"
                    />
                    {nullCheckArray(orderData).map((data, index) => (
                      <div className="mapContainer" key={index}>
                        <TableRow
                          className="grid-cols-10"
                          first={data.id}
                          second={data.market_id}
                          third={data.type}
                          forth={data.amount}
                          fifth={data.executed_value}
                          sixth={data.price}
                          seventh={data.avg_price}
                          eighth={data.side}
                          ninth={data.createdAt}
                          tenth={data.updatedAt}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {tab == "Balance" ? (
              <>
                <h1>Balance</h1>
                <div className="flex-container">
                  <div className="scrollHandler">
                    <TableRow
                      className="grid-cols-5 head"
                      first="Sr No"
                      second="Member ID"
                      third="Currency"
                      forth="Balance"
                      fifth="Locked"
                    />
                    {nullCheckArray(balanceData).map((data, index) => (
                      <TableRow
                        className="grid-cols-5"
                        first={index + 1}
                        second={data.member_id}
                        third={data.currency_id}
                        forth={data.balance}
                        fifth={data.locked}
                      />
                      // <div className="tab-container" key={index}>
                      //   <h2>Currency: {data.currency_id}</h2>
                      //   <p>Balance : {data.balance}</p>
                      //   <p>Locked : {data.locked}</p>
                      //   <p>Member ID : {data.member_id}</p>
                      // </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {tab == "History" ? (
              <div className="container">
                <div className="table-container">
                  <h1>Trade Table</h1>
                  <div className="flex-container">
                    <div className="scrollHandler">
                      <TableRow
                        className="grid-cols-10 head"
                        first="Taker ID"
                        second="Maker ID"
                        third="Maker Fee"
                        forth="Taker Fee"
                        fifth="Market"
                        sixth="Price"
                        seventh="Amount"
                        eighth="Total"
                        ninth="Taker Type"
                        tenth="Trade Time"
                      />

                      {nullCheckArray(tradeTableData).map((data, index) => (
                        <div className="mapContainer" key={index}>
                          <TableRow
                            className="grid-cols-11"
                            first={data.id}
                            second={data.maker_id}
                            third={data.maker_fee}
                            forth={data.taker_id}
                            fifth={data.taker_fee}
                            sixth={data.market_id}
                            seventh={data.price}
                            eighth={data.amount}
                            ninth={data.total}
                            tenth={data.taker_type}
                            eleventh="-"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="table-container">
                  <h1>Order Table</h1>
                  <div className="flex-container">
                    <div className="scrollHandler">
                      <TableRow
                        className="grid-cols-10 head"
                        first="Order ID"
                        second="Market"
                        third="Type"
                        forth="Amount"
                        fifth="Executed"
                        sixth="Price"
                        seventh="Average"
                        eighth="Side"
                        ninth="Created"
                        tenth="Uploaded"
                      />
                      {nullCheckArray(orderTableData).map((data, index) => (
                        <div className="mapContainer" key={index}>
                          <TableRow
                            className="grid-cols-10"
                            first={data.id}
                            second={data.market_id}
                            third={data.type}
                            forth={data.amount}
                            fifth={data.executed_value}
                            sixth={data.price}
                            seventh={data.avg_price}
                            eighth={data.side}
                            ninth={data.createdAt}
                            tenth={data.updatedAt}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="table-container">
                  <h1>Deposit Table</h1>
                  <div className="flex-container">
                    <div className="scrollHandler">
                      <TableRow
                        className="grid-cols-7 head"
                        first="Deposit ID"
                        second="Tx ID"
                        third="Created At"
                        forth="Amount"
                        fifth="Currency"
                        sixth="Confirmation"
                        seventh="State"
                      />
                      {depositTableData !== null &&
                        depositTableData.map((data, index) => (
                          <div className="mapContainer" key={index}>
                            <TableRow
                              className="grid-cols-7"
                              first={data.id}
                              second={data.txid}
                              third={data.createdAt}
                              forth={data.amount}
                              fifth={data.currency_id}
                              sixth={data.price}
                              seventh={data.aasm_state}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                {withdrawTableData !== null && (
                  <div className="table-container">
                    <h1>Withdraw Table</h1>
                    <div className="flex-container">
                      <div className="scrollHandler">
                        <TableRow
                          className="grid-cols-9 head"
                          first="Withdraw ID"
                          second="Tx ID"
                          third="Created At"
                          forth="Error"
                          fifth="Amount"
                          sixth="Currency"
                          seventh="Fee"
                          eighth="Confirmation"
                          ninth="State"
                        />
                        {withdrawTableData.map((data, index) => (
                          <div className="mapContainer" key={index}>
                            <TableRow
                              className="grid-cols-9"
                              first={data.id}
                              second={data.txid}
                              third={data.createdAt}
                              forth={data.error}
                              fifth={data.amount}
                              sixth={data.currency_id}
                              seventh={data.fee}
                              eighth={data.confirmation}
                              ninth={data.aasm_state}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
            {tab == "Activity" ? (
              <>
                <h1>Activities</h1>
                {activities.map((data, index) => (
                  <div className="tab-container" key={index}>
                    <h2>
                      <b> Activity ID </b>: {data.id}
                    </h2>
                    <p>
                      <b> Member ID </b>: {data.user_id}
                    </p>
                    <p>
                      <b>Browser </b>: {data.ua_string}
                    </p>
                    <p>
                      <b>Remote Address </b> : {data.remote_addr}
                    </p>
                    <p>
                      <b>Created At </b> : {data.created_at}
                    </p>
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default KYCpage;
