import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { checkBlank } from "../../utils/validations";

function NewCurrency(props) {
  console.log("Props Value :", props.value);

  const [newCurrency, setNewCurrency] = useState({
    id: "",
    name: "",
    description: "",
    homepage: "",
    blockchain_key: "",
    parent_id: "",
    type: "",
    deposit_fee: "",
    min_deposit_amount: "",
    min_collection_amount: "",
    min_withdraw_amount: "",
    withdraw_fee: "",
    withdraw_limit_24h: "",
    withdraw_limit_72h: "",
    position: "",
    options: "",
    visible: "",
    deposit_enabled: "",
    withdrawl_enabled: "",
    transfer_enabled: "",
    base_factor: "",
    precision: "",
    icon_url: "",
    price: "",
  });

  const getPropsValue = () => setNewCurrency(props.value);

  useEffect(() => {
    if (props.value !== undefined) {
      getPropsValue();
    }
  }, []);

  const handleValidation = () => {
    let errorObj = {
      isError: false,
      title: "There are error's in the form",
      message: "",
    };
    if (
      checkBlank([
        newCurrency.id,
        newCurrency.name,
        newCurrency.type,
        newCurrency.deposit_fee,
        newCurrency.min_deposit_amount,
        newCurrency.min_collection_amount,
        newCurrency.withdraw_fee,
        newCurrency.withdraw_limit_24h,
        newCurrency.withdraw_limit_72h,
        newCurrency.position,
        newCurrency.visible,
        newCurrency.deposit_enabled,
        newCurrency.withdrawl_enabled,
        newCurrency.transfer_enabled,
        newCurrency.base_factor,
        newCurrency.precision,
        newCurrency.price,
      ])
    ) {
      errorObj.message = "Please fill all the required fields";
      errorObj.isError = true;
    }

    if (errorObj.isError) {
      swal(errorObj.title, errorObj.message, "error", {
        className: "sweetalert",
      });
      return true;
    }

    return false;
  };

  const createCurrency = async () => {
    if (handleValidation()) return;
    try {
      const res = await axios.post(
        `../baseAPI/cmrapi/ADMIN/postEditCurrency`,
        newCurrency,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("Response : ", res.data);
      props.close();
    } catch (err) {
      console.log(err);
    }
  };
  console.log("New CurrencyData", newCurrency);

  return (
    <div className="tab-container" style={{ width: "85vw" }}>
      <h1>
        {props.type} : {props.currency}{" "}
      </h1>
      <div className="MainBody">
        <div className="verticalScroll">
          <form>
            <div className="formContainer">
              <div className="inputHolder">
                <label>
                  ID: <b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.id}
                  onChange={(e) =>
                    setNewCurrency({ ...newCurrency, id: e.target.value })
                  }
                  placeholder="ID"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Name:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.name}
                  onChange={(e) =>
                    setNewCurrency({ ...newCurrency, name: e.target.value })
                  }
                  placeholder="Name"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>Description:</label>
                <br />
                <input
                  value={newCurrency.description}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      description: e.target.value,
                    })
                  }
                  placeholder="Description"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>Homepage:</label>
                <br />
                <input
                  value={newCurrency.homepage}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      homepage: e.target.value,
                    })
                  }
                  placeholder="Home Page"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>BlockChain Key:</label>
                <br />
                <input
                  value={newCurrency.blockchain_key}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      blockchain_key: e.target.value,
                    })
                  }
                  placeholder="Block Chain Key"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>Parent ID:</label>
                <br />
                <input
                  value={newCurrency.parent_id}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      parent_id: e.target.value,
                    })
                  }
                  placeholder="Parent ID"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Type:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.type}
                  onChange={(e) =>
                    setNewCurrency({ ...newCurrency, type: e.target.value })
                  }
                  placeholder="Type"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Deposit Fee:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.deposit_fee}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      deposit_fee: e.target.value,
                    })
                  }
                  placeholder="Deposit Fee"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Min Deposit Amount:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.min_deposit_amount}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      min_deposit_amount: e.target.value,
                    })
                  }
                  placeholder="Min Deposit Amount"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Min Collection Amount:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.min_collection_amount}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      min_collection_amount: e.target.value,
                    })
                  }
                  placeholder="Min Collection Amount"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Withdraw Fee:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.withdraw_fee}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      withdraw_fee: e.target.value,
                    })
                  }
                  placeholder="Withdraw Fee"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>Min Withdraw Amount:</label>
                <br />
                <input
                  value={newCurrency.min_withdraw_amount}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      min_withdraw_amount: e.target.value,
                    })
                  }
                  placeholder="Min Withdraw Amount"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Withdraw Limit 24H:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.withdraw_limit_24}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      withdraw_limit_24h: e.target.value,
                    })
                  }
                  placeholder="Withdraw Limit 24H"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Withdraw Limit 72H:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.withdraw_limit_72h}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      withdraw_limit_72h: e.target.value,
                    })
                  }
                  placeholder="Withdraw Limit 72H"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Position:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.position}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      position: e.target.value,
                    })
                  }
                  placeholder="Position"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>Options:</label>
                <br />
                <input
                  value={newCurrency.options}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      options: e.target.value,
                    })
                  }
                  placeholder="Options"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Visible:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.visible}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      visible: e.target.value,
                    })
                  }
                  placeholder="Visible"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Deposit Enabled:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.deposit_enabled}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      deposit_enabled: e.target.value,
                    })
                  }
                  placeholder="Deposit Enabled"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Withdrawal Enabled:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.withdrawl_enabled}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      withdrawl_enabled: e.target.value,
                    })
                  }
                  placeholder="Withdrawal Enabled"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Transfer Enabled:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.transfer_enabled}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      transfer_enabled: e.target.value,
                    })
                  }
                  placeholder="Transfer Enabled"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Base Factor:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.base_factor}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      base_factor: e.target.value,
                    })
                  }
                  placeholder="Base Factor"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Precision:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.precision}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      precision: e.target.value,
                    })
                  }
                  placeholder="Precision"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>Icon URL:</label>
                <br />
                <input
                  value={newCurrency.icon_url}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      icon_url: e.target.value,
                    })
                  }
                  placeholder="Icon URL"
                  type="text"
                />
              </div>
              <div className="inputHolder">
                <label>
                  Price:<b className="required">*</b>
                </label>
                <br />
                <input
                  value={newCurrency.price}
                  onChange={(e) =>
                    setNewCurrency({
                      ...newCurrency,
                      price: e.target.value,
                    })
                  }
                  placeholder="Price"
                  type="text"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <button
        onClick={() => {
          createCurrency();
        }}
      >
        {props.type}
      </button>
    </div>
  );
}

export default NewCurrency;
