import { Close } from "@mui/icons-material";
import { Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { formatDate, nullCheck } from "../../utils/DataFormat";

const initialModalData = {
  id: "",
  created_at: "",
  side: "",
  status: "",
  market_id: "",
  member_id: "",
  size: "",
  price: "",
  filled_size: "",
  executed_value: "",
  funds: "",
  avg_price: "",
  maker_fee: "",
  taker_fee: "",
  type: "",
  settled: "",
  remote_id: "",
};

const OrderManagement = () => {
  const [reqOrderData, setReqOrderData] = useState({
    status: "done",
    sort: "id",
    page: 1,
    limit: 10,
    order: "ASC",
  });
  const [orderData, setOrderData] = useState([]);
  const [rowsState, setRowsState] = React.useState({
    page: 1,
    pageSize: 10,
  });

  const [open, setOpen] = useState(false);
  const [orderModalData, setOrderModalData] = useState(initialModalData);

  const handleModalClose = () => {
    setOpen(false);
    setOrderModalData(initialModalData);
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: "150",
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => {
                setOrderModalData(params.row);
                setOpen(true);
              }}
            >
              View Details
            </button>
          </>
        );
      },
    },
    { field: "side", headerName: "Side", width: "100" },
    { field: "status", headerName: "Status", width: "100" },
    { field: "market_id", headerName: "Market ID", width: "150" },
    { field: "size", headerName: "Size", width: "250" },
    { field: "price", headerName: "Price", width: "250" },
    { field: "executed_value", headerName: "Executed Value", width: "250" },
    { field: "funds", headerName: "Funds", width: "250" },
    { field: "avg_price", headerName: "Average Price", width: "250" },
    {
      field: "fee",
      headerName: "Fee (Maker + Taker Fee)",
      width: "250",
      renderCell: (params) => {
        return (
          Number(params.row.maker_fee) + Number(params.row.taker_fee)
        ).toFixed(16);
      },
    },
    { field: "type", headerName: "Type", width: "100" },
    { field: "remote_id", headerName: "Remote ID", width: "100" },
  ];

  const sortByList = [
    {
      id: "id",
      name: "ID",
    },
    {
      id: "market_id",
      name: "Market ID",
    },
    {
      id: "member_id",
      name: "Member ID",
    },
    {
      id: "status",
      name: "Status",
    },
    {
      id: "settled",
      name: "Settled",
    },
    {
      id: "remote_id",
      name: "Remote ID",
    },
  ];

  const rowCountList = [10, 15, 20, 25];

  useEffect(() => {
    const getOrdersData = async () => {
      try {
        const res = await axios.post(
          "baseAPI/cmrapi/ADMIN/getOrders",
          reqOrderData,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("user")).access
              }`,
            },
          }
        );
        setOrderData(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getOrdersData();
  }, [reqOrderData]);

  return (
    <>
      <div className="grid-container">
        <div className="home-grid-item item-11">
          <h3 style={{ height: "auto" }}>ORDERS DATA</h3>
          <div className="orderSorter" style={{ margin: 0 }}>
            <div className="selectHolder">
              <small>Order By</small>
              <select
                value={reqOrderData.order}
                onChange={(e) =>
                  setReqOrderData({ ...reqOrderData, order: e.target.value })
                }
              >
                <option value="ASC">ASC</option>
                <option value="DESC">DESC</option>
              </select>
            </div>
            <div className="selectHolder">
              <small>Sort</small>
              <select
                value={reqOrderData.sort}
                onChange={(e) =>
                  setReqOrderData({ ...reqOrderData, sort: e.target.value })
                }
              >
                {sortByList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="selectHolder">
              <small>Count</small>
              <select
                value={reqOrderData.limit}
                onChange={(e) =>
                  setReqOrderData({ ...reqOrderData, limit: e.target.value })
                }
              >
                {rowCountList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="selectHolder">
              <small>Page</small>
              <select
                value={reqOrderData.page}
                onChange={(e) =>
                  setReqOrderData({ ...reqOrderData, page: e.target.value })
                }
              >
                {[...Array(10)].map((item, index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="selectHolder">
              <small>Status</small>
              <select
                value={reqOrderData.status}
                onChange={(e) =>
                  setReqOrderData({ ...reqOrderData, status: e.target.value })
                }
              >
                <option value="wait">Wait</option>
                <option value="fail">Failed</option>
                <option value="done">Done</option>
                <option value="pending">Pending</option>
              </select>
            </div>
          </div>
          <DataGrid
            sx={{
              color: "white",
              backgroundColor: "#191c24",
              height: "70vh",
            }}
            rows={orderData}
            columns={columns}
            pagination
            paginationMode="server"
            pageSize={orderData.length}
            page={rowsState.page - 1}
            rowCount={orderData.length}
            onPageChange={(newPage) => {
              setRowsState(newPage + 1);
            }}
            rowsPerPageOptions={[orderData.length]}
          />
        </div>
      </div>
      <Modal open={open} onClose={handleModalClose}>
        <OrderModal orderData={orderModalData} setOpen={handleModalClose} />
      </Modal>
    </>
  );
};

const OrderModal = React.forwardRef(({ orderData, setOpen }, ref) => {
  const tableRowList = [
    { id: "id", label: "ID" },
    { id: "member_id", label: "Member ID" },
    { id: "market_id", label: "Market ID" },
    { id: "remote_id", label: "Remote ID" },
    { id: "side", label: "Side" },
    { id: "size", label: "Size" },
    { id: "status", label: "Status" },
    { id: "funds", label: "Funds" },
    { id: "price", label: "Price" },
    { id: "avg_price", label: "Average Price" },
    { id: "fee", label: "Fee (Maker + Taker Fee)" },
    { id: "filled_size", label: "Filled" },
    { id: "executed_value", label: "Executed Value" },
    { id: "type", label: "Type" },
    { id: "settled", label: "Settled" },
    { id: "created_at", label: "Created At" },
  ];
  const renderLogic = ({ id, type }) => {
    if (["completed_at", "updated_at", "created_at"].includes(id))
      return formatDate(orderData[id]);
    if (id === "fee")
      return nullCheck(
        (Number(orderData.maker_fee) + Number(orderData.taker_fee)).toFixed(8)
      );
    return nullCheck(orderData[id]);
  };
  return (
    <div className="tab-container" style={{ width: 600 }}>
      <div style={{ position: "relative" }}>
        <h1>Order Details</h1>
        <span
          onClick={setOpen}
          style={{
            position: "absolute",
            right: 5,
            top: 5,
            cursor: "pointer",
            color: "white",
          }}
        >
          <Close />
        </span>
      </div>
      <div className="MainBody">
        <div className="verticalScroll">
          <table style={{ width: "100%", textAlign: "left", color: "white" }}>
            <tbody>
              {tableRowList.map((row) => (
                <tr key={row.id}>
                  <th>{row.label}</th>
                  <td>
                    <p>:&nbsp;{renderLogic(row)}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default OrderManagement;
