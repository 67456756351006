import { Link } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../AuthContext/AuthContext";
import jwt_decode from "jwt-decode";
import { sleep } from "../../../utils/sleep";
import { useLocation } from "react-router-dom";

function BankAssitantDashBoard() {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  useEffect(async () => {
    await sleep(3000);
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, []);

  useEffect(() => {
    if (
      jwt_decode(user.access).urole != "ADMIN" &&
      jwt_decode(user.access).urole != "BANKTXASSISTANT"
    ) {
      History.push("/");
    } else {
      return;
    }
  }, [user.access, History]);

  return (
    <div>
      <div className="topicSelect">
        <div className="topic__info">
          <p>Select The Page:</p>
        </div>
        <div className="grid-container">
          <Link to="/banking/withdrawStatus" className="topicItem link">
            <h1>WITHDRAW</h1>
            <p>STATUS</p>
            <p>
              <small>Approval</small>
            </p>
          </Link>
          <Link to="/banking/depositStatus" className="topicItem link">
            <h1>DEPOSIT</h1>
            <p>STATUS</p>
            <p>
              <small>Approval</small>
            </p>
          </Link>
          <Link to="/banking/summary" className="topicItem link">
            <h1>BANKING</h1>
            <p>SUMMARY</p>
          </Link>
          <Link to="/banking/depositConfirmation" className="topicItem link">
            <h1>DEPOSIT</h1>
            <p>CONFIRMATION</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BankAssitantDashBoard;
