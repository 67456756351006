import axios from "axios";
import React, { useEffect, useState } from "react";
import "./kycReview.scss";
// import Zoom from "react-img-zoom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function KycReview({ userData, Approve, Reject }) {
  const [isValid, setIsValid] = useState();
  const [isInValid, setIsInValid] = useState();
  const [fetchedData, setFetchedData] = useState(userData);

  const [width, setWidth] = useState(280);

  const [reason, setReason] = useState("");

  useEffect(() => {
    if (isValid === true) {
      setIsInValid(false);
    } else if (isValid === false) {
      setIsInValid(true);
    }
  }, [isValid]);

  console.log("Reason : ", reason);

  useEffect(() => {
    if (window.innerWidth <= 600) {
      setWidth(280);
    } else {
      setWidth(window.innerWidth * 0.3);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    const getImages = async () => {
      const res = await axios.post(
        `../baseAPI/cmrapi/KYCASSISTANT/getManualReviewDocDetail`,
        { id: userData.id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      setFetchedData(res.data.data);
    };
    getImages();
  }, [userData.id]);

  // const approveKYCDocs = async () => {
  //   try {
  //     const res = axios.post(
  //       "../baseAPI/cmrapi/KYCASSISTANT/approveKycDoc/",
  //       { user_id: userData.user_id, id: userData.id },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${
  //             JSON.parse(localStorage.getItem("user")).access
  //           }`,
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       }
  //     );
  //     setApprovedData(res.data);
  //   } catch (err) {
  //     console.log(err);
  //     window.alert("Error in Fetching");
  //   }
  // };

  // console.log("Approved Data :", approvedData);

  // const rejectKYCDocs = async () => {
  //   try {
  //     const res = axios.post(
  //       "../baseAPI/cmrapi/KYCASSISTANT/rejectKycDocs/",
  //       { user_id: userData.user_id, id: userData.id, reason: reason },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${
  //             JSON.parse(localStorage.getItem("user")).access
  //           }`,
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       }
  //     );
  //     setRejectedData(res.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // console.log("Rejected Response :", rejectedData);

  return (
    <>
      {" "}
      <div className="kycReview">
        <div className="doc_info">
          <p>
            <b>Doc Type :</b> {fetchedData.doc_type.toUpperCase()}
          </p>
          <p>
            <b>Doc ID :</b> {fetchedData.doc_id}
          </p>
          <p>
            <b>Name : </b>
            {fetchedData.name}
          </p>
        </div>

        <p>
          <b>Doc Photos : </b>
        </p>
        <div className="doc_imgs">
          <div className="doc_front">
            <p>Front Of Doc</p>
            <div className="img_container">
              <Zoom>
                <img
                  style={{ width: "30vw", maxWidth: "300px" }}
                  src={fetchedData.doc_front}
                  alt=""
                />
              </Zoom>
              {/* <Zoom
                img={fetchedData.doc_front}
                zoomScale={3}
                width={width}
                height={window.innerHeight * 0.4}
                transitionTime={0.5}
              /> */}
            </div>
          </div>
          {fetchedData.doc_back == null ? (
            <></>
          ) : (
            <div className="doc_back">
              <p>Back Of Doc</p>
              <div className="img_container">
                <Zoom>
                  <img
                    style={{ width: "30vw", maxWidth: "300px" }}
                    src={fetchedData.doc_back}
                    alt=""
                  />
                </Zoom>
              </div>
            </div>
          )}
        </div>

        <div className="doc_verification">
          <p>Does The Doc ID matches with the id in the image uploaded ?</p>
          <input
            type="checkbox"
            value="yes"
            name="yes"
            checked={isValid ? true : false}
            onClick={() => {
              setIsValid(true);
              setReason("");
            }}
          />
          <label> Yes</label>
          <input
            type="checkbox"
            checked={isInValid ? true : false}
            value="no"
            name="no"
            onClick={() => {
              setIsValid(false);
              setReason("Not a Valid KYC document.");
            }}
          />
          <label> No</label>
        </div>

        {isInValid ? (
          <div className="rejectReason">
            <p>What's Wrong In the Above Doc :</p>
            <div className="inputContainer">
              {/* <input
                type="text"
                placeholder="Reason For Rejecting"
                required
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              /> */}
              {/* <div className="suggestions">
                <p>Not </p>
              </div> */}
              <select
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              >
                <option value="Not a Valid KYC document.">
                  Not a Valid KYC document.
                </option>
                <option value="Document blurred.">
                  Document blurred or Unclear
                </option>
                <option value="Name of Document doesn't match the name on Ampiy account">
                  Name of the document doesn't match the name on Ampiy account
                </option>
                <option value="ID doesn't match the ID in the image">
                  ID doesn't match the ID in the image
                </option>
                <option value="Suspicious Document">Suspicious Document</option>
                <option value="Other">Other</option>
              </select>
              {reason === "" ? (
                <>
                  <p>
                    <small>Reason must not be Empty !</small>
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="mainBtns">
          <button
            className={isValid ? "approve_btn" : "none"}
            disabled={isValid ? false : true}
            onClick={() => {
              Approve(userData);
            }}
          >
            APPROVE
          </button>
          <button
            className={reason !== "" ? "reject_btn" : "none"}
            disabled={reason === "" ? true : false}
            onClick={() => {
              Reject(userData, reason);
            }}
          >
            REJECT
          </button>
        </div>
      </div>
    </>
  );
}

export default KycReview;
