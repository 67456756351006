import React, { useContext, useEffect } from "react";
import { AuthContext } from "../AuthContext/AuthContext";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";

function Management() {
  const { user } = useContext(AuthContext);
  const History = useHistory();

  const location = useLocation();

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(async () => {
    await sleep(3000);
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, []);

  useEffect(() => {
    if (
      jwt_decode(user.access).urole != "MANAGEMENT" &&
      jwt_decode(user.access).urole != "ADMIN"
    ) {
      History.push("/");
    } else {
      return;
    }
  }, [user.access, History]);
  return <div>Management</div>;
}

export default Management;
