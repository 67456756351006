/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext/AuthContext";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import { formatDate, nullCheck } from "../utils/DataFormat";

const initialTableData = {
  page: 1,
  count: 0,
  data: [],
};

const initialModalData = {
  modalTitle: "",
  currency_id: "",
  debit: "",
  credit: "",
  code: "",
  reference_type: "",
  reference_id: "",
  created_at: "",
};

function Accounting() {
  const [accountingData, setAccountingData] = useState({
    assest: initialTableData,
    liability: initialTableData,
    revenue: initialTableData,
    expense: initialTableData,
  });

  const [accountModalData, setAccountModalData] = useState(initialModalData);

  const [open, setOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const History = useHistory();

  const location = useLocation();

  const handleModalClose = () => {
    setOpen(false);
    setAccountModalData(initialModalData);
  };

  useEffect(() => {
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (
      jwt_decode(user.access).urole !== "ACCOUNTANT" &&
      jwt_decode(user.access).urole !== "ADMIN"
    ) {
      History.push("/");
    } else {
      return;
    }
  }, [user.access, History]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => {
                setAccountModalData(params.row);
                setOpen(true);
              }}
            >
              View Details
            </button>
          </>
        );
      },
    },
    { field: "currency_id", headerName: "Currency ID", flex: 1 },
    { field: "credit", headerName: "Credit", flex: 1 },
    { field: "debit", headerName: "Debit", flex: 1 },
  ];

  const getAssetList = async () => {
    try {
      const res = await axios.get(
        `baseAPI/cmrapi/ADMIN/getAssetList/?page=${accountingData.assest.page}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      setAccountingData((oldAccountingData) => {
        return {
          ...oldAccountingData,
          assest: { ...oldAccountingData.assest, ...res.data },
        };
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getExpenseList = async () => {
    try {
      const res = await axios.get(
        `baseAPI/cmrapi/ADMIN/getExpenseList/?page=${accountingData.expense.page}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      setAccountingData((oldAccountingData) => {
        return {
          ...oldAccountingData,
          expense: { ...oldAccountingData.expense, ...res.data },
        };
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getLiabilityList = async () => {
    try {
      const res = await axios.get(
        `baseAPI/cmrapi/ADMIN/liablityList/?page=${accountingData.liability.page}&_limit=10`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      setAccountingData((oldAccountingData) => {
        return {
          ...oldAccountingData,
          liability: { ...oldAccountingData.liability, ...res.data },
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLiabilityList();
    getExpenseList();
    getAssetList();
  }, []);

  useEffect(() => {
    getAssetList();
  }, [accountingData.assest.page]);

  useEffect(() => {
    getExpenseList();
  }, [accountingData.expense.page]);

  useEffect(() => {
    getLiabilityList();
  }, [accountingData.liability.page]);

  const tableTypes = [
    {
      title: "Assets",
      id: "assest",
      tableData: accountingData.assest,
    },
    {
      title: "Liability",
      id: "liability",
      tableData: accountingData.liability,
    },
    {
      title: "Revenue",
      id: "revenue",
      tableData: accountingData.revenue,
    },
    {
      title: "Expenses",
      id: "expense",
      tableData: accountingData.expense,
    },
  ];

  return (
    <>
      <div className="home">
        <div className="grid-container">
          {tableTypes.map(({ title, id, tableData }) => (
            <div className="home-grid-item item-12" key={id}>
              <h3>{title}</h3>
              <DataGrid
                sx={{
                  color: "white",
                  backgroundColor: "#191c24",
                  height: "80%",
                }}
                rows={tableData.data}
                columns={[...columns]}
                pagination
                paginationMode="server"
                pageSize={tableData.data.length || 0}
                rowsPerPageOptions={[tableData.data.length || 0]}
                page={Number(tableData.page) - 1}
                rowCount={Number(tableData.count)}
                onPageChange={(newPage) => {
                  setAccountingData((oldAccountingData) => {
                    return {
                      ...oldAccountingData,
                      [id]: {
                        ...oldAccountingData[id],
                        page: newPage + 1,
                      },
                    };
                  });
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <Modal open={open} onClose={handleModalClose}>
        <AccountingModal
          modalData={accountModalData}
          setOpen={handleModalClose}
        />
      </Modal>
    </>
  );
}

const AccountingModal = React.forwardRef(({ modalData, setOpen }, ref) => {
  const tableRowList = [
    { id: "currency_id", label: "Currency ID" },
    { id: "reference_id", label: "Reference ID" },
    { id: "reference_type", label: "Reference Type" },
    { id: "debit", label: "Debit" },
    { id: "credit", label: "Credit" },
    { id: "code", label: "Code" },
    { id: "created_at", label: "Created At" },
  ];
  const renderLogic = ({ id }) => {
    if (["completed_at", "updated_at", "created_at"].includes(id))
      return formatDate(modalData[id]);
    return nullCheck(modalData[id]);
  };
  return (
    <div className="tab-container" style={{ width: 600, marginTop: 10 }}>
      <div style={{ position: "relative" }}>
        <h1>Details</h1>
        <span
          onClick={setOpen}
          style={{
            position: "absolute",
            right: 5,
            top: 5,
            cursor: "pointer",
            color: "white",
          }}
        >
          <Close />
        </span>
      </div>
      <div className="MainBody">
        <div className="verticalScroll">
          <table style={{ width: "100%", textAlign: "left", color: "white" }}>
            <tbody>
              {tableRowList.map((row) => (
                <tr key={row.id}>
                  <th>{row.label}</th>
                  <td>
                    <p>:&nbsp;{renderLogic(row)}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
export default Accounting;
