import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../AuthContext/AuthContext";
import "../styles/home.scss";
import { DataGrid } from "@mui/x-data-grid";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Navbar from "../Components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, nullCheck, nullCheckArray } from "../utils/DataFormat";
import { Doughnut, Pie } from "react-chartjs-2";
import { Chart, Tooltip, Title, ArcElement, Legend } from "chart.js";

Chart.register(Tooltip, Title, ArcElement, Legend);

function Home() {
  const [reqOrderData, setReqOrderData] = useState({
    status: "done",
    sort: "created_at",
    page: 1,
    limit: 10,
    order: "ASC",
  });

  const [userCount, setUserCount] = useState("");
  const [page, setPage] = useState(1);

  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 25,
  });
  const [rowCountState, setRowCountState] = useState();
  const [orderData, setOrderData] = useState();
  const [dashBoardData, setDashBoardData] = useState({
    revenue: [{}],
    last_login_24: [{}],
    users_count_by_level: [{}],
    user_join_count: [{}],
    dep: [{}],
    remote_null_order: [],
    completed_order: [],
    pending_order: [],
    pending_doc: [],
  });

  const { user } = useContext(AuthContext);
  const History = useHistory();

  const location = useLocation();

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const data = {
    labels: ["Level 0", "Level 1", "Level 2"],
    datasets: [
      {
        label: "User KYC Level",
        data: dashBoardData?.users_count_by_level.map((item) => item.count),
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  useEffect(async () => {
    await sleep(3000);
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, []);

  useEffect(() => {
    if (
      jwt_decode(user.access).urole != "ADMIN" &&
      jwt_decode(user.access).urole != "MANAGEMENT"
    ) {
      History.push("/");
    } else {
      return;
    }
  }, [user.access, History]);

  useEffect(() => {
    const getOrdersData = async () => {
      try {
        const res = await axios.post(
          "baseAPI/cmrapi/ADMIN/getOrders",
          reqOrderData,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("user")).access
              }`,
            },
          }
        );
        setOrderData(res.data?.data);
        console.log("res : ", res.data);
        console.log("orderData :", orderData);
      } catch (err) {
        console.log(err);
      }
    };
    getOrdersData();
  }, [reqOrderData]);

  useEffect(() => {
    const adminDashboard = async () => {
      try {
        const res = await axios.get("baseAPI/cmrapi/ADMIN/AdminDashoard/", {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        });
        setDashBoardData(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    adminDashboard();
    const getTotalUsers = async () => {
      try {
        const res = await axios.get(`baseAPI/cmrapi/ADMIN/getusers/`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        });
        setUserCount(res.data.count);
      } catch (error) {
        console.log(error);
      }
    };
    getTotalUsers();
  }, []);

  const CompleteOrdersColumns = [
    { field: "coin_sum", headerName: "Sum", width: "300" },
    { field: "market_id", headerName: "Market Pair", width: "130" },
    {
      field: "total_order_value",
      headerName: "Total Order Value",
      width: "300",
      flex: "1",
    },
  ];

  const PendingOrdersColumns = [
    { field: "side", headerName: "Side", width: "300" },
    { field: "market_id", headerName: "Market", width: "130" },
    {
      field: "count",
      headerName: "Count",
      width: "300",
      flex: "1",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: "90" },
    { field: "type", headerName: "Type", width: "100" },
    { field: "market_id", headerName: "Market Pair", width: "130" },
    { field: "member_id", headerName: "Member ID", width: "100" },
    { field: "price", headerName: "Price", width: "300" },
    {
      field: "avg_price",
      headerName: "Average Price",
      width: "300",
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: "120",
      renderCell: (params) => {
        return formatDate(params.row.created_at);
      },
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: "120",
      renderCell: (params) => {
        return formatDate(params.row.updated_at);
      },
    },
    { field: "side", headerName: "Side", width: "90", flex: "1" },
    // { field: "", headerName: "", width: "" },
  ];
  console.log("DashBoard Data:", dashBoardData);

  return (
    <div className="home">
      <div className="grid-container">
        {/* <div className="home-grid-item item-1">
          <h3>Pending Docs</h3>
        </div> */}
        <div className="home-grid-item item-2">
          <h2>Liability</h2>
          <p>
            {dashBoardData.dep[0].sum}{" "}
            <small>{dashBoardData.dep[0].currency_id}</small>
          </p>
        </div>
        <div className="home-grid-item item-3">
          <h2>Expenses : </h2>
          <div className="holder">
            <p>Credit : {nullCheck(dashBoardData.expense)[0]?.credit}</p>
            <p>Debit : {nullCheck(dashBoardData.expense)[0]?.debit}</p>
            <p>Currency : {nullCheck(dashBoardData.expense)[0]?.currency_id}</p>
          </div>
        </div>
        <div className="home-grid-item item-4">
          <h2>Assets</h2>
          <div className="holder">
            <p>Credit : {nullCheck(dashBoardData?.asset)[0]?.credit}</p>
            <p>Debit : {nullCheck(dashBoardData?.asset)[0]?.debit}</p>
            <p>Currency : {nullCheck(dashBoardData?.asset)[0]?.currency_id}</p>
          </div>
          {/* <p>{nullCheck(dashBoardData?.asset)}</p> */}
        </div>
        <div className="home-grid-item item-5">
          <h2>Revenue :</h2>
          <p>
            {dashBoardData.revenue[0]?.revenue}{" "}
            <small>{dashBoardData?.revenue[0]?.currency_id}</small>{" "}
          </p>
        </div>
        <div className="home-grid-item item-6">
          <h3>User KYC Levels</h3>
          <div className="chartContainer">
            <Doughnut data={data} />
          </div>
          <div className="InfoHolder">
            {nullCheckArray(dashBoardData.users_count_by_level).map((item) => (
              <p>
                <b>Level {item.level}</b> : {item.count}
              </p>
            ))}
          </div>
        </div>
        <div className="home-grid-item item-7">
          <h3>Pending Orders</h3>
          <DataGrid
            sx={{
              color: "white",
              backgroundColor: "#191c24",
              height: "80%",
            }}
            rows={nullCheckArray(dashBoardData?.pending_order)}
            columns={PendingOrdersColumns}
            pagination
            {...rowsState}
            paginationMode="server"
            pageSize={10}
            rowsPerPageOptions={[25]}
            getRowId={(row) => Math.random()}
            page={page - 1}
            rowCount={rowCountState}
            onPageChange={(newPage) => {
              setPage(newPage + 1);
            }}
          />
        </div>
        <div className="home-grid-item item-8">
          <h2>Users Joined :</h2>
          <p>
            User Joined in 1 Day :{" "}
            {dashBoardData?.user_join_count[0].user_joined_1day}
          </p>
          <p>
            User Joined in 1 Week :
            {dashBoardData?.user_join_count[0].user_joined_1week}
          </p>
          <p>
            User Joined in 1 Month :
            {dashBoardData?.user_join_count[0].user_joined_1month}
          </p>
        </div>
        <div className="home-grid-item item-9">
          <h2>Last Login in 24H</h2>
          <p>{dashBoardData?.last_login_24[0].count}</p>
        </div>
        <div className="home-grid-item item-10">
          <h2>Total Users:</h2>
          <p>{userCount}</p>
        </div>
        <div className="home-grid-item item-10">
          <h2>Pending Documnets:</h2>
          <p>{dashBoardData.pending_doc.map((item) => item.count)}</p>
        </div>

        <div className="home-grid-item item-11">
          <h3>ORDERS DATA</h3>
          <div className="orderSorter">
            <div className="selectHolder">
              <small>Status</small>
              <select
                value={reqOrderData.status}
                onChange={(e) =>
                  setReqOrderData({ ...reqOrderData, status: e.target.value })
                }
              >
                <option value="wait">Wait</option>
                <option value="fail">Failed</option>
                <option value="done">Done</option>
                <option value="pending">Pending</option>
              </select>
            </div>
            <div className="selectHolder">
              <small>Order By</small>
              <select
                value={reqOrderData.order}
                onChange={(e) =>
                  setReqOrderData({ ...reqOrderData, order: e.target.value })
                }
              >
                <option value="ASC">ASC</option>
                <option value="DESC">DESC</option>
              </select>
            </div>
            <div className="selectHolder">
              <small>Sort</small>
              <select
                value={reqOrderData.sort}
                onChange={(e) =>
                  setReqOrderData({ ...reqOrderData, sort: e.target.value })
                }
              >
                <option value="created_at">Created At</option>
                <option value="updated_at">Updated At</option>
              </select>
            </div>
            <div className="selectHolder">
              <small>Count</small>
              <select
                value={reqOrderData.limit}
                onChange={(e) =>
                  setReqOrderData({ ...reqOrderData, limit: e.target.value })
                }
              >
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
              </select>
            </div>
            {/* <div className="selectHolder">
              <small>Page</small>
              <select
                value={reqOrderData.page}
                onChange={(e) =>
                  setReqOrderData({ ...reqOrderData, page: e.target.value })
                }
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
              </select>
            </div> */}
          </div>
          <DataGrid
            sx={{
              color: "white",
              backgroundColor: "#191c24",
              height: "70%",
            }}
            rows={orderData === null ? [] : orderData}
            columns={columns}
            pagination
            {...rowsState}
            paginationMode="server"
            pageSize={10}
            rowsPerPageOptions={[25]}
            page={reqOrderData.page - 1}
            rowCount={rowCountState}
            onPageChange={(newPage) => {
              setReqOrderData({ ...reqOrderData, page: newPage });
            }}
          />
        </div>
        <div className="home-grid-item item-12">
          <h3>Completed Orders</h3>
          <DataGrid
            sx={{
              color: "white",
              backgroundColor: "#191c24",
              height: "80%",
            }}
            rows={nullCheckArray(dashBoardData?.completed_order)}
            columns={CompleteOrdersColumns}
            pagination
            {...rowsState}
            paginationMode="server"
            pageSize={10}
            rowsPerPageOptions={[25]}
            getRowId={(row) => Math.random()}
            page={page - 1}
            rowCount={rowCountState}
            onPageChange={(newPage) => {
              setPage(newPage + 1);
            }}
          />
        </div>
        <div className="home-grid-item item-12">
          <h3>Coin Liability</h3>
          <DataGrid
            sx={{
              color: "white",
              backgroundColor: "#191c24",
              height: "80%",
            }}
            rows={nullCheckArray(dashBoardData?.remote_null_order)}
            columns={CompleteOrdersColumns}
            pagination
            {...rowsState}
            paginationMode="server"
            pageSize={10}
            rowsPerPageOptions={[25]}
            getRowId={(row) => Math.random()}
            page={page - 1}
            rowCount={rowCountState}
            onPageChange={(newPage) => {
              setPage(newPage + 1);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
export { Navbar };
