import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext/AuthContext";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Modal from "../Components/Modal";
import Loading from "../Components/Loading/Loading";

function BankAssistant() {
  const [isLoading, setIsLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [bankingSummary, setBankingSummary] = useState([]);

  const { user } = useContext(AuthContext);
  const History = useHistory();

  const location = useLocation();

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(async () => {
    await sleep(3000);
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, []);

  useEffect(() => {
    if (
      jwt_decode(user.access).urole != "BANKXASSISTANT" &&
      jwt_decode(user.access).urole != "ADMIN"
    ) {
      History.push("/");
    } else {
      return;
    }
  }, [user.access, History]);

  useEffect(() => {
    const getBankingSummary = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `/baseAPI/cmrapi//ADMIN/getBankingSummary`,
          {
            headers: {
              Authorization: `Bearer ${user.access}`,
            },
          }
        );
        setBankingSummary(res.data.summary);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getBankingSummary();
  }, []);

  const getBankingSummaryFilterd = async () => {
    try {
      const res = await axios.get(
        `/baseAPI/cmrapi//ADMIN/getBankingSummary?fromDate=${fromDate}&toDate=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${user.access}`,
          },
        }
      );
      setBankingSummary(res.data.summary);
    } catch (err) {
      console.log(err);
    }
  };

  console.log("Banking Summary:", bankingSummary);
  console.log("Form Date:", fromDate);
  console.log("To Date:", toDate);

  return (
    <>
      <div className="MainBody">
        <div className="Relative">
          <div className="AlignRight">
            <button
              style={{ marginLeft: "125px" }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              Filter
            </button>
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <dic className="tab-wrapper">
            {bankingSummary.map((data, index) => (
              <div className="tab-container-list">
                <h1>{data.currency_id}</h1>
                <p>
                  <b>Debit: </b>
                  {data.debit}
                </p>
                <p>
                  <b>Credit: </b>
                  {data.credit}
                </p>
              </div>
            ))}
          </dic>
        )}
      </div>
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className="tab-container">
          <h1>Filter</h1>
          <p>Select the Starting Date :</p>
          <input
            type="date"
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
          />
          <p>Select Till Date :</p>
          <input
            type="date"
            onChange={(e) => {
              setToDate(e.target.value);
            }}
          />
          <br />
          <button
            onClick={() => {
              setIsOpen(false);
              getBankingSummaryFilterd();
            }}
          >
            {" "}
            Search
          </button>
        </div>
      </Modal>
    </>
  );
}

export default BankAssistant;
