import swal from "sweetalert";

export function showError(error) {
  console.log("Show Error Res : ", error);
  // console.log('[ERROR--->]: In ShowError Func', error)
  let errorCodes = {
    500: "KGF",
    501: "ROCKY",
    502: "ADHEERA",
    504: "DODDAMMA",
    400: "GARUDA",
    401: "VANARA",
    403: "",
    429: "",
  };
  var myJson = error.error;
  console.log("MEs", myJson);
  let msg = "";
  if (!myJson.hasOwnProperty("message")) {
    if (error.status < 500 && error.status >= 400) {
      if (typeof myJson === "object") {
        // console.log("keys are=",keys)
        var keys = Object.keys(myJson);
        let errorMsg = myJson[keys[0]];
        if (Array.isArray(errorMsg)) {
          // var len=;
          if (errorMsg.length == 1)
            msg = JSON.stringify(errorMsg[0])
              .replace("{", "")
              .replace("}", "")
              .replace(/"/g, "");
          else if (errorMsg.length > 1) {
            let combinedMsg = "";
            Object.keys(errorMsg).forEach((ele) => {
              combinedMsg += JSON.stringify(ele)
                .replace("{", "")
                .replace("}", "")
                .replace(/"/g, "");
            });
            msg = combinedMsg;
          }
        } else if (typeof errorMsg === "string") {
          msg = msg + errorMsg + "</br>";
        }
      } else {
        msg = myJson;
      }
    } else {
      msg = myJson;
    }
  } else {
    msg = myJson.message;
  }

  var icon, message, title;
  if (myJson.title) {
    title = myJson.title;
  } else {
    title = "ERROR CODE: NARACHI";
  }

  message = msg;
  icon = "error";

  console.log("first", icon, title, message);

  swal({
    title: title,
    icon: icon,
    text: message,
    dangerMode: true,
    className: "sweetalert",
  });
}
