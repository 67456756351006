import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../AuthContext/AuthContext";
import jwt_decode from "jwt-decode";
import { sleep } from "../../../utils/sleep";
import { useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "../../../Components/Loading/Loading";

import axios from "axios";
import { formatDate, nullCheck } from "../../../utils/DataFormat";
import Modal from "../../../Components/Modal";
import swal from "sweetalert";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import NewDeposit from "../../../Components/Modal/newDeposit";

function DepositSS() {
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [depositConfList, setDepositConfList] = useState({ data: [] });
  const [uid, setUID] = useState("");
  const [depConf_id, setDepConfID] = useState("");
  const [rejectBody, setRejectBody] = useState({
    depConf_id: depConf_id,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [img, setImg] = useState("");

  const [page, setPage] = useState(1);
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 25,
  });

  const { user } = useContext(AuthContext);
  const location = useLocation();

  useEffect(async () => {
    await sleep(3000);
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, []);

  useEffect(() => {
    if (
      jwt_decode(user.access).urole != "ADMIN" &&
      jwt_decode(user.access).urole != "BANKTXASSISTANT"
    ) {
      History.push("/");
    } else {
      return;
    }
  }, [user.access, History]);

  console.log("Rejecct bOdy:", rejectBody);

  useEffect(() => {
    const getDepositConf = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `../baseAPI/cmrapi/ADMIN/getDepositConf/?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("user")).access
              }`,
            },
          }
        );
        setDepositConfList(res.data);
        setRefresh(false);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getDepositConf();

    if (refresh) {
      getDepositConf();
    }
  }, [page, refresh]);

  const rejectDepConf = async (id) => {
    try {
      const res = await axios.post(
        `../baseAPI/cmrapi/ADMIN/rejectDepConf/`,
        {
          depConf_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        }
      );
      if (res.status === 200) {
        swal(`${res.data?.msg}`, {
          icon: "warning",
          className: "sweetalert",
        });
        setRefresh(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const column = [
    { field: "id", headerName: "ID", width: "90" },
    { field: "txid", headerName: "Transaction ID", width: "180" },
    { field: "status", headerName: "Status", width: "190" },
    { field: "member_id", headerName: "Member ID", width: "190" },
    { field: "processed_at", headerName: "Processed At", width: "190" },
    {
      field: "created_at",
      headerName: "Created At",
      width: "120",
      renderCell: (params) => {
        return formatDate(params.row.created_at);
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: "180",
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={() => {
                setIsOpen(true);
                setImg(params.row.link);
              }}
            >
              View Details
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="kyc__body">
        {isLoading ? (
          <Loading />
        ) : (
          <div className="Container">
            <h1>Deposit Confirmation</h1>
            {depositConfList?.data.map((item, i) => (
              <div className="table-container" key={i}>
                <div className="flex-divider">
                  <div className="left">
                    <div className="depositInfo">
                      <p>
                        <b>ID : {nullCheck(item?.id)}</b>
                      </p>
                      <p>
                        <b>Member ID : </b>
                        {nullCheck(item?.member_id)}
                      </p>
                      <p>
                        <b>Transaction ID: </b> {nullCheck(item?.txid)}
                      </p>
                      <p>
                        <b>Status : </b> {nullCheck(item?.status)}
                      </p>
                      <p>
                        <b>Created At : </b>{" "}
                        {formatDate(nullCheck(item?.created_at))}
                      </p>
                      <p>
                        <b>Processed At : </b>{" "}
                        {nullCheck(formatDate(item?.processed_at))}
                      </p>
                    </div>
                  </div>
                  <div className="right">
                    <Zoom>
                      <img
                        className="depositScreenShot"
                        src={nullCheck(item?.link)}
                        alt=""
                      />
                    </Zoom>
                  </div>
                </div>
                <div className="AlignCenter Padding ">
                  <div className="Btns">
                    <button
                      className="approve_btn"
                      onClick={() => {
                        setIsOpen(true);
                        setUID(
                          depositConfList.data[i]?.image_key.split("/")[1]
                        );
                        setDepConfID(depositConfList.data[i]?.id);
                        console.log("UID : ", uid);
                        // swal({
                        //   title: "Are you sure?",
                        //   text: "Once Approved, you will not be able to recover the deposit!",
                        //   icon: "warning",
                        //   buttons: true,
                        //   dangerMode: true,
                        //   className: "sweetalert",
                        // }).then((value) => {
                        //   if (value) {
                        //   } else {
                        //     swal("Deposit is not Approved!", {
                        //       icon: "warning",
                        //       className: "sweetalert",
                        //     });
                        //   }
                        // });
                      }}
                    >
                      Create
                    </button>
                    <button
                      className="reject_btn"
                      onClick={() => {
                        swal({
                          title: "Are you sure?",
                          text: "Once Rejected Screenshot, you will not be able to recover the Deposit Confirmation!",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                          className: "sweetalert",
                        }).then((value) => {
                          if (value) {
                            rejectDepConf(depositConfList.data[i]?.id);
                          } else {
                            swal("Screen Shot is not Rejected!", {
                              icon: "warning",
                              className: "sweetalert",
                            });
                          }
                        });
                      }}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <div className="Operations">
              <div className="flex-divider">
                <div className="left">
                  <p>
                    <b>Count : </b>
                    {depositConfList?.count}
                  </p>
                </div>
                <div className="right AlignRight ">
                  <p>
                    <b>Current Page : </b> {page}
                  </p>
                  <button
                    disabled={page === 1 || page < 1}
                    className={page === 1 ? "None" : ""}
                    onClick={() => {
                      setPage(page - 1);
                    }}
                  >
                    Prev
                  </button>
                  <button
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          // <div style={{ height: 400, width: "100%" }}>
          //   <DataGrid
          //     sx={{
          //       color: "white",
          //       backgroundColor: "#191c24",
          //       height: "80vh",
          //     }}
          //     rows={depositConfList.data}
          //     columns={column}
          //     pagination
          //     {...rowsState}
          //     paginationMode="server"
          //     pageSize={25}
          //     rowsPerPageOptions={[25]}
          //     page={page - 1}
          //     rowCount={rowCountState}
          //     onPageChange={(newPage) => {
          //       setPage(newPage + 1);
          //     }}
          //   />
          // </div>
        )}
      </div>

      {/* <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="tab-container">
          <img className="modalImg" src={img} alt="" />
        </div>
      </Modal> */}
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <NewDeposit
          UID={uid}
          onClose={() => {
            setIsOpen(false);
            setRefresh(true);
          }}
          depConf_id={depConf_id}
        />
      </Modal>
    </>
  );
}

export default DepositSS;
