import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../AuthContext/AuthContext";
import axios from "axios";
import Modal from "../../Components/Modal";
import NewCurrency from "../../Components/NewCurrency/NewCurrency";
import "./currencyData.scss";
import { formatDate } from "../../utils/DataFormat";

function CurrencyData() {
  const [isOpen, setIsOpen] = useState(false);
  const [currency, setCurrency] = useState({});

  const History = useHistory();
  const { user } = useContext(AuthContext);
  const location = useLocation();

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(async () => {
    await sleep(3000);
    const Pathname = location.pathname;
    localStorage.setItem("Pathname", Pathname);
  }, []);

  useEffect(() => {
    if (jwt_decode(user.access).urole != "ADMIN") {
      History.push("/");
    }
  }, [user.access, History]);

  const { id } = useParams();

  useEffect(() => {
    const getCurrencyDetails = async () => {
      try {
        const res = await axios.get(
          `../baseAPI/cmrapi/ADMIN/getCurrencyData?id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${user.access}`,
            },
          }
        );
        setCurrency(res.data.currencyData);
      } catch (err) {
        console.log(err);
      }
    };
    getCurrencyDetails();
  }, []);

  console.log("Currency:", currency);
  return (
    <>
      <div className="kyc__body">
        <div className="MainBody">
          <h1>{currency.name}</h1>
          <p>
            <b>Name:</b>
            {currency.name}
          </p>
          <p>
            <b>ID:</b>
            {currency.id}
          </p>
          <p>
            <b>Type:</b>
            {currency.type}
          </p>
          <p>
            <b>Home Page:</b>
            <a>{currency.homepage}</a>
          </p>
          <p>
            <b>Price:</b>
            {currency.price}
          </p>
          <p>
            <b>Icon:</b>
            {/* {currency.icon_url} */}
            <img className="currencyData_img" src={currency.icon_url} alt="" />
          </p>
          <p>
            <b>Withdraw Fee:</b>
            {currency.withdraw_fee}
          </p>
          <p>
            <b>Withdraw Limit 24H:</b>
            {currency.withdraw_limit_24h}
          </p>
          <p>
            <b>Withdraw Limit 72H:</b>
            {currency.withdraw_limit_72h}
          </p>
          <p>
            <b>Deposit Fee:</b>
            {currency.deposit_fee}
          </p>
          <p>
            <b>Description:</b>
            {currency.description}
          </p>
          <p>
            <b>Position:</b>
            {currency.position}
          </p>
          <p>
            <b>Min Collection Amount:</b>
            {currency.min_collection_amount}
          </p>
          <p>
            <b>Min Deposit Amount:</b>
            {currency.min_deposit_amount}
          </p>
          <p>
            <b>Min Withdraw Amount:</b>
            {currency.min_withdraw_amount}
          </p>
          <p>
            <b>Created At:</b>
            {formatDate(currency.createdAt)}
          </p>
          <div className="Container">
            <button
              onClick={() => {
                setIsOpen(true);
              }}
              className="AlignCenter"
            >
              Update The Currency
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <NewCurrency
          currency={currency.id}
          type="Update The Currency"
          value={currency}
          close={() => setIsOpen(false)}
        />
      </Modal>
    </>
  );
}

export default CurrencyData;
