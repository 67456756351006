import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext/AuthContext";
import { Link } from "react-router-dom";
import "../styles/topicSelect.scss";
import jwt_decode from "jwt-decode";
import FullLogo from "../imgs/FullLogo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logout } from "../AuthContext/AuthActions";
import axios from "axios";

function TopicSelect() {
  const [totalUsers, setTotalUsers] = useState("");

  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const History = useHistory();

  console.log("User_Role =", jwt_decode(user.access).urole);

  console.log("User UID : ", jwt_decode(user.access).uid);

  const urole = jwt_decode(user.access).urole;
  useEffect(() => {
    localStorage.setItem("userDetails", jwt_decode(user.access));
    const getTotalUsers = async () => {
      try {
        const res = await axios.get(`baseAPI/cmrapi/ADMIN/getusers/`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
          },
        });
        setTotalUsers(res.data.count);
      } catch (error) {
        console.log(error);
      }
    };
    getTotalUsers();
  }, []);

  if (urole == "MEMBER")
    return (
      <div className="topicSelect">
        <div className="logo_img">
          <img src={FullLogo} alt="" />
        </div>
        <div
          className="logoutBtn"
          onClick={() => {
            dispatch(logout());
            History.push("/login");
          }}
        >
          <LogoutIcon />
        </div>
        <div className="topic__info">
          <p>No Apps Available For You </p>
        </div>
      </div>
    );
  if (urole == "KYCASSISTANT") {
    return (
      <div className="topicSelect">
        <div className="topic__logo">
          <img className="logo_img" src={FullLogo} alt="" />
        </div>
        <div
          className="logoutBtn"
          onClick={() => {
            dispatch(logout());
            History.push("/login");
          }}
        >
          <LogoutIcon />
        </div>
        <div className="topic__info">
          <p>Apps Available For You :</p>
        </div>
        <div className="grid-container">
          <Link to="/kycassistant/manualreviews" className="topicItem link">
            <h1>KYC</h1>
            <p>ADMINISTRATION</p>
          </Link>
        </div>
      </div>
    );
  } else if (urole == "ACCOUNTANT") {
    return (
      <div className="topicSelect">
        <div className="topic__logo">
          <img className="logo_img" src={FullLogo} alt="" />
        </div>
        <div
          className="logoutBtn"
          onClick={() => {
            dispatch(logout());
            History.push("/login");
          }}
        >
          <LogoutIcon />
        </div>
        <div className="topic__info">
          <p>Apps Available For You :</p>
        </div>
        <div className="grid-container">
          <Link to="/accounting" className="topicItem link">
            <h1>ACCOUNTING</h1>
            <p>ADMINISTRATION</p>
          </Link>
        </div>
      </div>
    );
  } else if (urole == "CUSSERVICE") {
    return (
      <div className="topicSelect">
        <div className="topic__logo">
          <img className="logo_img" src={FullLogo} alt="" />
        </div>
        <div
          className="logoutBtn"
          onClick={() => {
            dispatch(logout());
            History.push("/login");
          }}
        >
          <LogoutIcon />
        </div>
        <div className="topic__info">
          <p>Apps Available For You :</p>
        </div>
        <div className="grid-container">
          <Link to="/customercare" className="topicItem link">
            <h1>CUSTOMER</h1>
            <p>SERVICE ADMINISTRATION</p>
          </Link>
        </div>
      </div>
    );
  } else if (urole == "BANKTXASSISTANT") {
    return (
      <div className="topicSelect">
        <div className="topic__logo">
          <img className="logo_img" src={FullLogo} alt="" />
        </div>
        <div
          className="logoutBtn"
          onClick={() => {
            dispatch(logout());
            History.push("/login");
          }}
        >
          <LogoutIcon />
        </div>
        <div className="topic__info">
          <p>Apps Available For You :</p>
        </div>
        <div className="grid-container">
          <Link to="/banking" className="topicItem link">
            <h1>BANKING</h1>
            <p>ADMINISTRATION</p>
            <p>(Deposits/Withdrawals)</p>
          </Link>
        </div>
      </div>
    );
  } else if (urole == "ADMIN" || urole == "MANAGEMENT") {
    return (
      <div className="topicSelect">
        <div className="topic__logo">
          <img className="logo_img" src={FullLogo} alt="" />
        </div>
        <div
          className="logoutBtn"
          onClick={() => {
            dispatch(logout());
            History.push("/login");
          }}
        >
          <LogoutIcon />
        </div>
        <div className="topic__info">
          <p>Total Number Of Users : {totalUsers}</p>
          <p>Apps Available For You :</p>
        </div>
        <div className="grid-container">
          <Link to="/kycassistant/manualreviews" className="topicItem link">
            <h1>KYC</h1>
            <p>ADMINISTRATION</p>
          </Link>
          <Link to="/banking" className="topicItem link">
            <h1>BANKING</h1>
            <p>ADMINISTRATION</p>
            <p>(Deposits/Withdrawals)</p>
          </Link>
          <Link to="/accounting" className="topicItem link">
            <h1>ACCOUNTING</h1>
            <p>ADMINISTRATION</p>
          </Link>
          <Link to="/customercare" className="topicItem link">
            <h1>CUSTOMER</h1>
            <p>SERVICE ADMINISTRATION</p>
          </Link>
          <Link to="/management" className="topicItem link">
            <h1>EXCHANGE MGMT</h1>
            <p>PORTAL</p>
            <p>(Currencies,Fees,etc.)</p>
          </Link>
          <Link to="/home" className="topicItem link">
            <h1>SUPERADMIN</h1>
            <p>PORTAL</p>
          </Link>
        </div>
      </div>
    );
  }
}

export default TopicSelect;
