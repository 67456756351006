import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";

function NewDeposit({ onClose, UID = "", depConf_id = "" }) {
  const [metaInfo, setMetaInfo] = useState({
    name: "",
    reference_no: "",
    account_no: "",
    bank_name: "",
    amount: "",
    transfer_description: "",
  });
  const [newDeposit, setNewDeposit] = useState({
    currency: "INR",
    member_uid: `${UID}`,
    depConf_id: `${depConf_id}`,
    amount: "",
    type: "Deposit:fiat",
    transfer_type: 400,
    meta: {
      name: "",
      reference_no: "",
      account_no: "",
      bank_name: "",
      amount: "",
      transfer_description: "",
    },
  });

  const [userData, setUserData] = useState();

  console.log("USerData:", userData);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const res = await axios.get(
          `../baseAPI/cmrapi/ADMIN/search?word=${UID}&column=uid`,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("user")).access
              }`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setUserData(res.data?.data);
        setMetaInfo({ ...metaInfo, name: res.data?.data[0]?.first_name });
      } catch (err) {
        console.log(err);
      }
    };
    fetchUserDetails();
  }, []);

  const AddNewDepositRecord = async () => {
    try {
      const res = await axios.post(
        `../baseAPI/cmrapi/ADMIN/createDeposit`,
        newDeposit,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).access
            }`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      onClose();
      if (res.status === 200) {
        swal(
          "Deposit Added!",
          "New Deposit is Recorded Successfully!",
          "success",
          {
            className: "sweetalert",
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log("New Deposit : ", newDeposit);

  useEffect(() => {
    setNewDeposit({ ...newDeposit, meta: metaInfo });
  }, [metaInfo]);
  return (
    <div className="tab-container" style={{ width: "85vw" }}>
      <h1>Add New Deposit Record</h1>
      <div className="MainBody">
        <div className="verticalScroll">
          <form>
            <div className="formContainer">
              <div className="inputHolder">
                <label>
                  Currency :<b>*</b>
                </label>
                <br />
                <select
                  value={newDeposit.currency}
                  onChange={(e) =>
                    setNewDeposit({ ...newDeposit, currency: e.target.value })
                  }
                >
                  <option value="INR">INR</option>
                </select>
              </div>
              <div className="inputHolder">
                <label>
                  Member UID<b></b>
                </label>
                <br />
                <input
                  value={newDeposit.member_uid}
                  onChange={(e) =>
                    setNewDeposit({ ...newDeposit, member_uid: e.target.value })
                  }
                />
              </div>
              <div className="inputHolder">
                <label>
                  Amount<b></b>
                </label>
                <br />
                <input
                  type="number"
                  value={newDeposit.amount}
                  onChange={(e) =>
                    setNewDeposit({ ...newDeposit, amount: e.target.value })
                  }
                />
              </div>
              <div className="inputHolder">
                <label>
                  Type<b></b>
                </label>
                <br />
                {/* <input
                  value={newDeposit.type}
                  onChange={(e) =>
                    setNewDeposit({ ...newDeposit, type: e.target.value })
                  }
                /> */}
                <select
                  value={newDeposit.type}
                  onChange={(e) =>
                    setNewDeposit({ ...newDeposit, type: e.target.value })
                  }
                >
                  <option value="Deposit:fiat">Fiat</option>
                  <option value="Deposit:crypto">Crypto</option>
                </select>
              </div>
              <div className="inputHolder">
                <label>
                  Transfer Type<b></b>
                </label>
                <br />
                {/* <input
                  value={newDeposit.transfer_type}
                  onChange={(e) =>
                    setNewDeposit({
                      ...newDeposit,
                      transfer_type: e.target.value,
                    })
                  }
                /> */}
                <select
                  value={newDeposit.transfer_type}
                  onChange={(e) =>
                    setNewDeposit({
                      ...newDeposit,
                      transfer_type: e.target.value,
                    })
                  }
                >
                  <option value={100}>UPI</option>
                  <option value={200}>Crypto</option>
                  <option value={300}>Mobikwik</option>
                  <option value={400}>Bank Transfer</option>
                  <option value={500}>Cash</option>
                </select>
              </div>
              <div className="inputHolder">
                <label>
                  Name<b></b>
                </label>
                <br />
                <input
                  value={metaInfo.name}
                  onChange={(e) =>
                    setMetaInfo({ ...metaInfo, name: e.target.value })
                  }
                />
              </div>
              <div className="inputHolder">
                <label>
                  Reference Number<b></b>
                </label>
                <br />
                <input
                  type="number"
                  value={metaInfo.reference_no}
                  onChange={(e) =>
                    setMetaInfo({ ...metaInfo, reference_no: e.target.value })
                  }
                />
              </div>
              <div className="inputHolder">
                <label>
                  Account No<b></b>
                </label>
                <br />
                <input
                  type="number"
                  value={metaInfo.account_no}
                  onChange={(e) =>
                    setMetaInfo({ ...metaInfo, account_no: e.target.value })
                  }
                />
              </div>
              <div className="inputHolder">
                <label>
                  Bank Name<b></b>
                </label>
                <br />
                <input
                  value={metaInfo.bank_name}
                  onChange={(e) =>
                    setMetaInfo({ ...metaInfo, bank_name: e.target.value })
                  }
                />
              </div>
              {depConf_id === "" ? (
                <div className="inputHolder">
                  <label>
                    Transfer Description<b></b>
                  </label>
                  <br />
                  <input
                    value={metaInfo.transfer_description}
                    onChange={(e) =>
                      setMetaInfo({
                        ...metaInfo,
                        transfer_description: e.target.value,
                      })
                    }
                  />
                </div>
              ) : (
                <div className="inputHolder">
                  <label>
                    Deposit Confirmation ID<b></b>
                  </label>
                  <br />
                  <input
                    disabled
                    value={newDeposit.depConf_id}
                    onChange={(e) =>
                      setNewDeposit({
                        ...newDeposit,
                        depConf_id: e.target.value,
                      })
                    }
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <button
        onClick={() => {
          AddNewDepositRecord();
        }}
      >
        Add New Deposit Record
      </button>
    </div>
  );
}

export default NewDeposit;
