import Home from "./pages/Home";
import Login from "./pages/Login";
import TopicSelect from "./pages/TopicSelect";
import KYCpage from "./pages/KYCpage";
import "./styles/app.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext/AuthContext";
import CustomerService from "./pages/CustomerService";
import Management from "./pages/Management";
import BankAssistant from "./pages/BankAssistant";
import Accounting from "./pages/Accounting";
import Error from "./pages/404";
import Users from "./pages/User";
import ManualReviews from "./pages/kycAssistant/ManualReviews";
import Sidebar from "./Components/Sidebar";

import Navbar from "./Components/navbar/Navbar";
import Currency from "./pages/Currency/Currency";
import CurrencyData from "./pages/CurrencyData/CurrencyData";
import WithdrawStatus from "./pages/WithDrawStatus/WithdrawStatus";
import DepositStatus from "./pages/DepositStatus/DepositStatus";
import BankAssitantDashBoard from "./pages/BankingAssitant/Dashboard/BankAssitantDashBoard";
import axios from "axios";
import swal from "sweetalert";
import { logout } from "./AuthContext/AuthActions";
import DepositSS from "./pages/BankingAssitant/Dashboard/DepositSS";
import OrderManagement from "./pages/OrderManagement/OrderManagement";
import { showError } from "./utils/showError";
import { useHistory } from "react-router-dom";

function App() {
  const { user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const History = useHistory();

  // const ReturnURL = localStorage.getItem("Pathname");
  axios.interceptors.request.use((request) => {
    // console.log("App Request :", request);
    return request;
  });

  axios.interceptors.response.use(
    (response) => {
      // console.log("App Response :", response.status);
      return response;
    },
    (error) => {
      // console.log("Object Entries -->", Object.entries(err));
      console.log("Error:", error.response.data);

      let err = error.response;
      err.error = {};
      let errorCodes = {
        500: "KGF",
        501: "ROCKY",
        502: "ADHEERA",
        504: "DODDAMMA",
        400: "GARUDA",
        401: "VANARA",
        403: "",
        429: "",
      };
      switch (err.status) {
        case 0: {
          err.error.message = "No Internet connection, Turn On Internet";
          err.error.title = errorCodes[500];
          showError(err.error.message);
          break;
        }
        case 401: {
          swal({
            title: "Un-Authorized",
            icon: "error",
            text: "You are not Authorized to access this!",
            type: "warning",
            showCancelButton: false,
            confirmButtonText: "OK",
            dangerMode: true,
            className: "sweetalert",
          }).then((value) => {
            if (value) {
              localStorage.setItem("user", null);
              dispatch(logout);
              window.location.href = "/login";
            }
          });
          // err.error.title = "You have Been Logged Out";
          // err.error.message = "Please relogin again";
          // showError(err)
          //   .then(
          //     localStorage.setItem("user", null),
          //     dispatch(logout),
          //     History.push("/login")
          //   )
          //   .then((window.location.href = "/login"));
          break;
        }
        case 502: {
          // this.authService.logout();
          // this.router.navigate(['auth/phone-login']);
          err.error.message = "Invalid Session";
          err.error.title = "ERROR CODE: ADHEERA";
          showError(err);
          break;
        }
        default: {
          console.log("Error Default Case : ", err);
          err.error.message = err.data.error;
          err.error.title = null;
          // err.error.title = "ERROR CODE: DODDAMMA"
          // err.error.message="Unknown Error";
          showError(err?.data);
        }
      }
      return error;
      // if (error.response.status >= 402 || error.response.status === 400) {
      //   swal(
      //     "SomeThing Went Wrong!",
      //     `Status Code : ${error.response.status}`,
      //     "error",
      //     {
      //       className: "sweetalert",
      //     }
      //   );
      // } else if (error.response.status >= 500) {
      //   swal(
      //     "Server Issue!",
      //     `Status Code : ${error.response.status}`,
      //     "error",
      //     {
      //       className: "sweetalert",
      //     }
      //   );
      // }
      // if (error.response.status === 401) {
      //   swal({
      //     title: "Un-Authorized",
      //     icon: "error",
      //     text: "You are not Authorized to access this!",
      //     type: "warning",
      //     showCancelButton: false,
      //     confirmButtonText: "OK",
      //     dangerMode: true,
      //     className: "sweetalert",
      //   }).then((value) => {
      //     if (value) {
      //       localStorage.setItem("user", null);
      //       dispatch(logout);
      //       window.location.href = "/login";
      //     }
      //   });
      // }
    }
  );

  return (
    <div className="App">
      <Router>
        <Switch>
          {/* Auth Part*/}

          <Route exact path="/">
            {user ? <TopicSelect /> : <Redirect to="/login" />}
          </Route>
          <Route path="/login">
            {user ? <Redirect to={"/"} /> : <Login />}
          </Route>

          {/* After Auth */}
          <>
            <div className="body">
              {user && (
                <>
                  <Sidebar isOpen={isOpen} />
                  <div className="bodyContainer">
                    <Navbar Toggle={() => setIsOpen(!isOpen)} />

                    <Route path="/home">
                      <Home />
                    </Route>

                    <Route path="/kyc/:uid">
                      <KYCpage />
                    </Route>
                    <Route path="/customercare">
                      <CustomerService />
                    </Route>
                    <Route path="/management">
                      <Home />
                    </Route>
                    <Route exact path="/banking">
                      {/* <BankAssistant /> */}
                      <BankAssitantDashBoard />
                    </Route>
                    <Route path="/banking/withdrawStatus">
                      <WithdrawStatus />
                    </Route>
                    <Route path="/banking/depositStatus">
                      {<DepositStatus />}
                    </Route>
                    <Route path="/banking/summary">
                      <BankAssistant />
                    </Route>
                    <Route path="/banking/depositConfirmation">
                      <DepositSS />
                    </Route>
                    <Route path="/accounting">
                      <Accounting />
                    </Route>
                    <Route path="/users">
                      <Users />
                    </Route>
                    <Route path="/kycassistant/manualreviews">
                      <ManualReviews />
                    </Route>
                    <Route path="/currency">
                      <Currency />
                    </Route>
                    <Route path="/currencydata/:id">
                      <CurrencyData />
                    </Route>
                    <Route path="/order">
                      <OrderManagement />
                    </Route>
                  </div>
                </>
              )}
            </div>
          </>
          <Route path="*">
            <Error />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
