export const checkBlank = (valueArr) => {
  if (valueArr.length === 0) return false;
  let isBlank = false;
  valueArr.forEach((value) => {
    if (value === "" || value === null || value === undefined) isBlank = true;
  });
  return isBlank;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
